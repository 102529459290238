import { Dialog } from "@headlessui/react";
import React, { useRef } from "react";

function Rules(props) {
  let completeButtonRef = useRef(null);
  return (
    <Dialog
      open={props.isOpen}
      initialFocus={completeButtonRef}
      onClose={() => props.setIsOpen(false)}
      className="relative z-50"
      as="div"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-[#15151566]" aria-hidden="true" />

      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex items-center justify-center">
        {/* The actual dialog panel  */}

        <Dialog.Panel className="mx-auto lg:w-[35.75rem] w-[95%] pb-10 overflow-y-scroll no-scrollbar lg:h-[20.3125rem] h-[25rem] bg-white rounded-[1.25rem]">
          <div className="h-[100%] flex flex-col items-center justify-center px-5">
            <p
              ref={completeButtonRef}
              onClick={() => props.setIsOpen(false)}
              className="hidden font-poppins text-[#BE8688] text-[1.25rem] font-medium"
            >
              close
            </p>
            <div className="flex items-center gap-2 justify-start w-[100%] mb-5">
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.25 8H19.5V9.5H14.25V8ZM14.25 11.75H19.5V13.25H14.25V11.75ZM14.25 15.5H19.5V17H14.25V15.5ZM4.5 8H9.75V9.5H4.5V8ZM4.5 11.75H9.75V13.25H4.5V11.75ZM4.5 15.5H9.75V17H4.5V15.5Z"
                  fill="#151515"
                />
                <path
                  d="M21 4.25H3C2.60218 4.25 2.22064 4.40804 1.93934 4.68934C1.65804 4.97064 1.5 5.35218 1.5 5.75V19.25C1.5 19.6478 1.65804 20.0294 1.93934 20.3107C2.22064 20.592 2.60218 20.75 3 20.75H21C21.3978 20.75 21.7794 20.592 22.0607 20.3107C22.342 20.0294 22.5 19.6478 22.5 19.25V5.75C22.5 5.35218 22.342 4.97064 22.0607 4.68934C21.7794 4.40804 21.3978 4.25 21 4.25ZM3 5.75H11.25V19.25H3V5.75ZM12.75 19.25V5.75H21V19.25H12.75Z"
                  fill="#151515"
                />
              </svg>
              <p className="text-[#151515] text-[1.25rem] font-bold">Rules</p>
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.2583 13.1669L12.9 11.5252L15.575 8.85023C16.1333 8.28356 15.7333 7.31689 14.9333 7.31689L9.74167 7.31689L5.06667 7.31689C4.26667 7.31689 3.86667 8.28356 4.43333 8.85023L8.75 13.1669C9.43333 13.8586 10.5667 13.8586 11.2583 13.1669Z"
                  fill="#151515"
                />
              </svg>
            </div>
            <div className="w-[100%] mb-5">
              <h1 className="font-bold mb-3">
                For trader to qualify for Top Clan payout:
              </h1>
              <ol className="list-decimal pl-10">
                <li>Clan must have a minimum of 3 member</li>
                <li>Trader must have traded for a minimum of 10 days.</li>
                <li>And must have completed their KYC</li>
              </ol>
            </div>
            <div className="w-[100%]">
              <h1 className="font-bold mb-3">For a trader to qualify for Top Trader:</h1>
              <ol className="list-decimal pl-10">
                <li>Trader must have traded for a minimum of 10 days.</li>
                <li>And must have completed their KYC</li>
              </ol>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

export default Rules;
