import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useRef } from "react";
import { Link } from "react-router-dom";
const menu = [
  {
    id: 1,
    title: "Home",
    link: "home",
  },
  {
    id: 2,
    title: "Leaderboard",
    link: "leaderboard",
  },
  {
    id: 3,
    title: "Clan",
    link: "clan",
  },
  {
    id: 4,
    title: "Payout",
    link: "payout",
  },
  {
    id: 5,
    title: "Request",
    link: "request",
  },
];

function MobileMenu(props) {
  let completeButtonRef = useRef(null);
  const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    window.location.href = "https://competition.gohamma.com/login";
  };

  return (
    <Transition appear show={props.isOpen} as={Fragment}>
      <Dialog
        open={props.isOpen}
        initialFocus={completeButtonRef}
        onClose={() => props.setIsOpen(false)}
        className="relative z-50"
        as="div"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div
          className="fixed inset-0 backdrop-blur-[5px]"
          aria-hidden="true"
          style={{ backgroundColor: ": rgba(255, 255, 255, 0.30)" }}
        />

        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex items-center justify-center">
          {/* The actual dialog panel  */}
          <Transition.Child
            as={Fragment}
            enter="transition ease-out duration-300 transform"
            enterFrom="opacity-0 translate-x-full"
            enterTo="opacity-100 translate-x-0"
            leave="transition ease-in duration-200 transform"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-full"
          >
            <Dialog.Panel className="mx-auto w-[12.5rem] pb-10 absolute top-0 left-0 h-[100vh] bg-white">
              <div className="h-[100%]">
                <div className="flex flex-col justify-between pt-10">
                  <p
                    ref={completeButtonRef}
                    onClick={() => props.setIsOpen(false)}
                    className="hidden font-poppins text-[#BE8688] text-[1.25rem] font-medium"
                  >
                    close
                  </p>
                  <Link
                    to="/"
                    className="ml-[0.5rem]"
                  >
                    <svg
                      className="ml-[1.37rem]"
                      width="70%"
                      height="206"
                      viewBox="0 0 940 206"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M865.04 63.1738H877.533V206H865.04V63.1738Z"
                        fill="#161616"
                      />
                      <path
                        d="M802.573 6.86662C802.573 3.07429 805.649 0 809.444 0H933.129C936.924 0 940 3.0743 940 6.86663V71.4131C940 75.2054 936.924 78.2797 933.129 78.2797H809.444C805.649 78.2797 802.573 75.2054 802.573 71.4131V6.86662Z"
                        fill="#19F256"
                      />
                      <path
                        d="M207.232 167.966H210.403V205.997H207.232V167.966Z"
                        fill="#161616"
                      />
                      <path
                        d="M197.707 164.799H220.964V179.251H197.707V164.799Z"
                        fill="#19F256"
                      />
                      <path
                        d="M75.0351 8.23995H119.149V205.999H75.0351V128.955H44.114V205.999H0V8.23995H44.114V85.2836H75.0351V8.23995Z"
                        fill="#161616"
                      />
                      <path
                        d="M228.858 8.23995L285.752 205.999H239.989L206.594 90.6396L173.2 205.999H127.437L184.744 8.23995H228.858Z"
                        fill="#161616"
                      />
                      <path
                        d="M403.99 8.23995H447.692V205.999H403.578V107.119L380.49 159.443H361.113L338.025 107.119V205.999H293.911V8.23995H338.025L371.008 82.3996L403.99 8.23995Z"
                        fill="#161616"
                      />
                      <path
                        d="M574.297 8.23995H617.999V205.999H573.885V107.119L550.797 159.443H531.42L508.332 107.119V205.999H464.218V8.23995H508.332L541.315 82.3996L574.297 8.23995Z"
                        fill="#161616"
                      />
                      <path
                        d="M727.701 8.23995L784.596 205.999H738.833L705.438 90.6396L672.043 205.999H626.28L683.587 8.23995H727.701Z"
                        fill="#161616"
                      />
                    </svg>

                    {/* <img src="https://res.cloudinary.com/ddlupbcws/image/upload/v1680791936/logo_2_ft9ja_1_fsh9iz.png" alt="" className='w-[5.75rem] ml-[1.37rem] mb-[4.06rem]' /> */}
                  </Link>
                  {/* <img src="https://res.cloudinary.com/ddlupbcws/image/upload/v1680791936/logo_2_ft9ja_1_fsh9iz.png" alt="" className='w-[5.75rem] ml-[2.31rem] mb-[3.81rem]' /> */}
                  <div className="flex flex-col">
                    {menu.map((me) => (
                      <Link
                        onClick={() => props.setIsOpen(false)}
                        key={me.id}
                        to={`/?${me.link}`}
                        className="font-plus font-medium text-[1rem] py-[0.55rem] border-b border-b-[#808080] pt-[0.94rem]"
                      >
                        <p className="pl-[2.56rem]">{me.title}</p>
                      </Link>
                    ))}
                    <p
                      onClick={logout}
                      className="pl-[2.56rem] mt-5 font-plus text-red-500 font-semibold"
                    >
                      Logout
                    </p>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default MobileMenu;
