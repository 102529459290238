import React, { useEffect, useMemo, useState } from 'react'
import RequestTable from './tables/RequestTable';
import axios from '../axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const headers = [
  {
    key: "name",
    label: "Name",
  },
  {
    key: "request",
    label: "Request",
  },
  {
    key: "date",
    label: "Date",
  },
  {
    key: "actions",
    label: "Actions",
  },
];

function Request() {
  const [reqPage, setResPage] = useState(0)
  const [data, setData] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [admin, setAdmin] = useState(false)
  console.log(admin)
  const params = useMemo(() => new URLSearchParams(window.location.search), []);


  const showRequestSuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const showRequestFailed = (message) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  useEffect(() => {
    if(params.get("trader_email")){
      axios.get(`leaderboard/join-clan/support/${params.get("trader_email")}`).then(res => {
        console.log(res)
        if (res.data[1].length <= 0) {
          setAdmin(res.data[0].admin)
          setResPage(0)
        } else {
          setResPage(1)
          setData(res.data[1])
        }
        // setRefresh(!refresh)
        // showRequestSuccess("Successfully sent join request")
      }).catch(err => {
        console.log(err)
        // showRequestFailed("Failed to send join request")
      })
    }else{
      axios.get(`leaderboard/join-clan/`).then(res => {
        console.log(res)
        if (res.data[1].length <= 0) {
          setAdmin(res.data[0].admin)
          setResPage(0)
        } else {
          setResPage(1)
          setData(res.data[1])
        }
        // setRefresh(!refresh)
        // showRequestSuccess("Successfully sent join request")
      }).catch(err => {
        console.log(err)
        // showRequestFailed("Failed to send join request")
      })
    }
    
  }, [params, refresh])

  return (
    <div className='lg:pl-[4.69rem] pl-[1rem] pr-[1rem] lg:pr-[2.25rem] pt-[2.25rem] pb-[10rem] '>
      <ToastContainer />
      {reqPage === 0 &&
        <div className='bg-white w-[100%] flex flex-col items-center justify-center py-[7.5rem] rounded-[1.25rem] px-[1rem] lg:px-0'>
          <svg xmlns="http://www.w3.org/2000/svg" width="205" height="195" viewBox="0 0 205 195" fill="none">
            <g clip-path="url(#clip0_580_920)">
              <path d="M43.5478 91.1918C41.076 59.7719 43.6096 2.07728 66.9063 12.2264C73.6772 15.1704 72.2824 24.1791 70.817 29.5012C67.2859 42.5148 48.7474 58.4369 47.9353 90.9089L43.5478 91.1918Z" fill="#0F3797" />
              <path d="M43.5479 91.1913C42.8593 98.6175 46.4257 106.813 55.2889 104.567C55.7277 104.442 56.1319 104.218 56.4702 103.911C56.8085 103.604 57.0718 103.224 57.2398 102.799L57.999 101.084C57.999 101.084 50.9986 102.269 50.5572 97.3356C50.1158 92.4025 49.6744 91.8632 47.9088 90.873L43.5479 91.1913Z" fill="#FF96A5" />
              <path d="M69.8369 75.924C68.1861 105.479 41.4731 166.922 34.7109 174.843L38.3215 177.946C38.3215 177.946 73.2797 144.776 84.3322 100.82C101.405 89.4238 91.3945 75.8887 91.3945 75.8887L69.8369 75.924Z" fill="#0F3797" />
              <path d="M74.0566 75.9238C74.0566 120.844 97.5299 164.446 108.573 179.307L112.211 176.001C112.211 176.001 91.2709 130.418 101.414 99.7054C112.211 93.9236 103.798 75.9238 103.798 75.9238H74.0566Z" fill="#205FAE" />
              <path d="M15.749 30.0496C19.3331 29.4838 28.108 31.8178 28.108 31.8178L28.3375 28.4229C28.3375 28.4229 38.3659 31.7471 38.2424 23.5252C43.6008 20.873 49.5243 22.3052 52.0138 24.0733C59.4291 20.2806 64.2403 15.3563 65.6175 9.83973C57.7801 8.51954 49.7879 8.4003 41.9147 9.4861L17.9295 9.36233L15.749 30.0496Z" fill="#FF96A5" />
              <path d="M28.2314 16.9205V19.0512C28.2314 19.0512 20.2864 17.5836 18.6709 20.3242C17.0554 23.0649 17.488 35.5568 15.0427 37.9173C11.644 41.1795 0.309006 38.9428 5.47329 29.4036C0.344317 29.4036 4.2727 21.7653 6.35607 20.7928C0.176588 17.3095 4.96128 4.65845 11.4851 6.44428C9.29575 1.33433 21.5929 0.971861 25.8568 6.26746C29.3879 0.706639 36.6797 2.0858 41.9323 9.50317C37.9421 10.3872 32.4247 16.8233 29.5733 15.7889C27.5341 9.97173 22.5728 12.7123 21.9725 14.9667C21.3722 17.2211 28.2314 16.9205 28.2314 16.9205Z" fill="#205FAE" />
              <path d="M23.1557 24.18C23.3675 25.4443 23.7736 26.4344 24.0385 26.3902C24.3033 26.346 24.4092 25.2674 24.1974 24.0032C23.9855 22.739 23.5794 21.74 23.3146 21.793C23.0497 21.8461 22.9438 22.907 23.1557 24.18Z" fill="#F97088" />
              <path d="M13.7184 37.0954C14.0368 36.8279 14.2828 36.4843 14.4334 36.0964C14.4449 36.0692 14.4508 36.0399 14.4507 36.0104C14.4507 35.9808 14.4447 35.9516 14.4332 35.9244C14.4217 35.8972 14.4048 35.8726 14.3836 35.852C14.3624 35.8315 14.3374 35.8154 14.3099 35.8047C14.2547 35.7839 14.1937 35.7852 14.1395 35.8082C14.0853 35.8313 14.042 35.8743 14.0185 35.9284C13.5771 37.0247 12.3148 37.5198 10.6287 37.2546C8.94253 36.9893 6.88565 35.8047 6.8062 34.5228C6.81282 34.4894 6.81198 34.455 6.80374 34.4221C6.79549 34.3891 6.78005 34.3584 6.75852 34.3321C6.73699 34.3058 6.70991 34.2846 6.67923 34.2701C6.64855 34.2556 6.61503 34.248 6.58109 34.248C6.54715 34.248 6.51363 34.2556 6.48295 34.2701C6.45227 34.2846 6.42519 34.3058 6.40366 34.3321C6.38213 34.3584 6.36668 34.3891 6.35844 34.4221C6.3502 34.455 6.34935 34.4894 6.35597 34.5228C6.45308 36.0787 8.6159 37.3695 10.558 37.6789C11.0963 37.808 11.6556 37.8228 12.1999 37.7223C12.7442 37.6218 13.2615 37.4083 13.7184 37.0954Z" fill="white" />
              <path d="M38.6573 8.3987C38.6983 8.3559 38.7212 8.29888 38.7212 8.23957C38.7212 8.18025 38.6983 8.12323 38.6573 8.08043C36.8917 5.94098 32.9016 3.96949 30.2355 6.00286C30.1971 6.04518 30.1758 6.10035 30.1758 6.15757C30.1758 6.2148 30.1971 6.26996 30.2355 6.31229C30.2561 6.33368 30.2808 6.35069 30.3081 6.36231C30.3354 6.37393 30.3648 6.37992 30.3945 6.37992C30.4241 6.37992 30.4535 6.37393 30.4808 6.36231C30.5081 6.35069 30.5328 6.33368 30.5534 6.31229C32.9987 4.44689 36.7328 6.31229 38.3483 8.31029C38.378 8.36259 38.427 8.40114 38.4848 8.41767C38.5426 8.4342 38.6045 8.42739 38.6573 8.3987Z" fill="white" />
              <path d="M0.370829 31.5167C0.370829 31.5167 0.370829 31.5167 0.370829 31.4725C0.972556 30.485 1.22447 29.3233 1.08589 28.1749C0.812226 25.4431 1.96867 23.2683 4.97896 21.1553C5.0052 21.1395 5.0277 21.1182 5.04492 21.0928C5.06213 21.0675 5.07365 21.0387 5.07869 21.0084C5.08372 20.9782 5.08215 20.9472 5.07408 20.9176C5.06601 20.888 5.05162 20.8605 5.03193 20.8371C4.99652 20.7882 4.94335 20.7552 4.88388 20.7453C4.82442 20.7354 4.76344 20.7493 4.71413 20.784C1.58025 23.0119 0.300209 25.3016 0.635667 28.2279C0.75571 29.2668 0.532445 30.3164 6.18547e-05 31.2161C-0.0168732 31.2398 -0.0289396 31.2667 -0.0354456 31.2952C-0.0419516 31.3236 -0.0427679 31.3531 -0.0378433 31.3818C-0.0329188 31.4106 -0.0223512 31.4381 -0.00675407 31.4628C0.00884302 31.4874 0.029158 31.5088 0.0530267 31.5255C0.0742015 31.5463 0.0993595 31.5627 0.126985 31.5735C0.15461 31.5844 0.184138 31.5896 0.213807 31.5888C0.243476 31.5879 0.272672 31.5811 0.299653 31.5687C0.326633 31.5563 0.350842 31.5386 0.370829 31.5167Z" fill="#205FAE" />
              <path d="M26.0949 6.54208C26.1383 6.50867 26.1677 6.46032 26.1775 6.40641C26.1873 6.35251 26.1767 6.29688 26.1479 6.25033C25.8243 5.7862 25.6759 5.222 25.7293 4.65846C25.7826 4.09491 26.0343 3.5687 26.4392 3.17375C26.4802 3.13095 26.5031 3.07393 26.5031 3.01462C26.5031 2.95531 26.4802 2.89829 26.4392 2.85549C26.3979 2.81499 26.3425 2.79231 26.2847 2.79231C26.2269 2.79231 26.1715 2.81499 26.1302 2.85549C25.8114 3.16545 25.5701 3.54635 25.4259 3.96725C25.2818 4.38815 25.2388 4.83718 25.3004 5.27785C24.1373 3.65645 23.1154 1.93797 22.246 0.141383C22.2337 0.114276 22.2161 0.0899353 22.1942 0.0698188C22.1722 0.0497023 22.1465 0.0342251 22.1185 0.0243174C22.0904 0.0144098 22.0607 0.0102767 22.031 0.012163C22.0014 0.0140494 21.9724 0.0219196 21.9458 0.0352982C21.918 0.0467626 21.8928 0.0638084 21.8718 0.0853861C21.8508 0.106964 21.8344 0.132614 21.8236 0.160768C21.8129 0.188921 21.808 0.218978 21.8093 0.249093C21.8106 0.279207 21.818 0.308742 21.8311 0.33588C21.8311 0.33588 23.8615 4.56175 25.7859 6.52439C25.8276 6.56048 25.8809 6.58033 25.936 6.58033C25.9911 6.58033 26.0444 6.56048 26.0861 6.52439L26.0949 6.54208Z" fill="#205FAE" />
              <path d="M105.44 67.6679C104.778 59.2426 91.0947 6.39276 65.6176 9.85832C40.1405 13.3239 59.1027 62.9027 69.8196 75.9251H103.789C103.789 75.9251 106.102 76.0842 105.44 67.6679Z" fill="#A9CBE8" />
              <path d="M91.024 85.3323C94.8112 80.797 97.1241 92.5198 97.1241 92.5198C97.1241 92.5198 98.7749 77.9857 101.414 75.9258H84.3325C84.3325 75.9258 87.2192 89.8765 91.024 85.3323Z" fill="#A9CBE8" />
              <path d="M67.0124 44.3009C66.9724 44.3017 66.9328 44.2924 66.8973 44.2739C66.8618 44.2553 66.8316 44.2282 66.8094 44.1948C66.7713 44.1488 66.7505 44.091 66.7505 44.0313C66.7505 43.9715 66.7713 43.9137 66.8094 43.8677C66.88 43.797 73.7922 36.3266 75.5754 29.7225C75.594 29.6564 75.6376 29.6001 75.6968 29.5654C75.7561 29.5308 75.8265 29.5206 75.8932 29.5369C75.9257 29.5461 75.9561 29.5616 75.9826 29.5826C76.0092 29.6036 76.0313 29.6296 76.0477 29.6592C76.0641 29.6888 76.0745 29.7213 76.0783 29.755C76.0821 29.7886 76.0792 29.8226 76.0697 29.8552C74.6485 35.1596 70.0139 40.9768 68.0629 43.2489C70.1992 42.1438 74.6573 39.5711 76.3434 36.6714C76.3798 36.6142 76.4367 36.5732 76.5024 36.5568C76.5681 36.5403 76.6375 36.5497 76.6965 36.583C76.7256 36.6002 76.751 36.6231 76.7713 36.6503C76.7915 36.6775 76.8061 36.7084 76.8144 36.7413C76.8226 36.7742 76.8242 36.8084 76.8191 36.8419C76.814 36.8754 76.8024 36.9076 76.7848 36.9366C74.4631 40.9237 67.4008 44.1683 67.0742 44.3097L67.0124 44.3009Z" fill="white" />
              <path d="M58.0697 91.7312C50.0275 61.266 42.2413 4.03998 66.9769 9.85718C74.154 11.5458 74.3923 20.6605 73.898 26.1506C72.715 39.5974 57.3281 58.5784 62.3335 90.688L58.0697 91.7312Z" fill="#205FAE" />
              <path d="M58.0698 91.7308C58.7231 99.1659 63.6843 106.592 72.0001 102.799C72.4069 102.599 72.7639 102.31 73.0449 101.953C73.3259 101.597 73.5239 101.182 73.6245 100.74L74.057 98.9183C74.057 98.9183 67.3832 101.332 66.0678 96.5667C64.7525 91.8015 64.2493 91.333 62.3337 90.6699L58.0698 91.7308Z" fill="#FF96A5" />
              <path d="M112.211 176.002C112.211 176.002 114.656 178.539 117.181 180.802C117.584 181.16 117.913 181.595 118.146 182.082C118.38 182.569 118.514 183.097 118.542 183.636C118.569 184.176 118.488 184.715 118.304 185.223C118.121 185.73 117.838 186.196 117.472 186.593C114.055 190.384 109.755 193.27 104.954 194.992H94.3608C94.0864 194.987 93.8226 194.885 93.6158 194.705C93.4089 194.524 93.2724 194.276 93.2302 194.005C93.188 193.733 93.2428 193.455 93.3851 193.22C93.5273 192.985 93.7478 192.808 94.0077 192.72C98.4216 191.296 103.409 189.378 105.36 187.477C108.203 183.517 109.492 180.511 108.618 179.308L112.211 176.002Z" fill="#FF96A5" />
              <path d="M38.3216 177.946L35.5408 189.793C35.434 190.256 35.2246 190.69 34.9279 191.061C34.6312 191.433 34.255 191.733 33.827 191.939C33.3989 192.145 32.9301 192.253 32.4551 192.253C31.9801 192.253 31.5111 192.147 31.0828 191.941C26.4596 189.75 22.3819 186.555 19.1462 182.588C15.9104 178.621 13.5979 173.982 12.3766 169.008C12.3334 168.788 12.3636 168.56 12.4623 168.358C12.5611 168.157 12.7231 167.994 12.9235 167.893C13.1239 167.793 13.3516 167.761 13.5717 167.803C13.7918 167.845 13.9922 167.958 14.1422 168.124C22.6699 177.946 30.4119 179.087 34.6845 174.808L38.3216 177.946Z" fill="#FF96A5" />
              <path d="M152.934 139.508H194.16V187.115C194.16 189.207 193.33 191.212 191.853 192.691C190.376 194.17 188.374 195.001 186.285 195.001H160.808C158.72 195.001 156.717 194.17 155.24 192.691C153.763 191.212 152.934 189.207 152.934 187.115V139.508Z" fill="#205FAE" />
              <path d="M140.071 139.508H181.297V187.115C181.297 189.207 180.468 191.212 178.991 192.691C177.514 194.17 175.511 195.001 173.423 195.001H147.946C145.857 195.001 143.854 194.17 142.378 192.691C140.901 191.212 140.071 189.207 140.071 187.115V139.508Z" fill="#0088A3" />
              <path d="M202.14 117.37H190.478L196.269 107.486C197.461 105.462 196.782 103.826 194.76 103.826H194.16C192.147 103.826 189.543 105.462 188.36 107.486L182.56 117.37H170.907C168.144 117.37 164.595 119.616 162.962 122.383L152.925 139.507H194.151L204.188 122.383C205.786 119.598 204.903 117.37 202.14 117.37Z" fill="#00CEC9" />
              <path d="M147.866 123.205C147.833 123.202 147.8 123.192 147.77 123.176C147.74 123.16 147.714 123.138 147.693 123.112C147.671 123.085 147.655 123.055 147.646 123.022C147.636 122.99 147.633 122.956 147.637 122.922C148.52 113.401 147.487 107.265 144.379 104.153C143.082 102.903 141.44 102.071 139.665 101.766C139.93 103.449 139.558 105.17 138.62 106.592C137.682 108.014 136.248 109.033 134.598 109.449C131.906 110.13 129.496 109.608 128.154 108.078C127.713 107.593 127.417 106.994 127.297 106.349C127.177 105.705 127.238 105.039 127.474 104.427C128.198 102.659 130.308 101.421 133.274 101.139C135.189 100.913 137.123 100.913 139.038 101.139C138.279 97.7967 134.819 94.393 127.562 95.7015C127.528 95.7098 127.493 95.7111 127.458 95.7052C127.424 95.6993 127.391 95.6864 127.361 95.6672C127.332 95.648 127.307 95.6229 127.288 95.5936C127.268 95.5643 127.255 95.5314 127.249 95.4968C127.243 95.4622 127.244 95.4267 127.252 95.3926C127.261 95.3585 127.276 95.3264 127.297 95.2983C127.318 95.2703 127.344 95.2468 127.375 95.2295C127.405 95.2122 127.439 95.2013 127.474 95.1975C135.181 93.8184 138.818 97.5227 139.586 101.103V101.227C141.53 101.52 143.333 102.417 144.741 103.791C147.963 107.018 149.084 113.295 148.149 122.975C148.147 123.009 148.138 123.043 148.122 123.073C148.106 123.104 148.084 123.131 148.058 123.152C148.031 123.174 148.001 123.19 147.968 123.199C147.935 123.208 147.9 123.21 147.866 123.205ZM136.39 101.483C135.34 101.483 134.298 101.554 133.274 101.651C130.511 101.917 128.56 103.004 127.915 104.622C127.712 105.143 127.658 105.711 127.761 106.261C127.864 106.812 128.12 107.322 128.498 107.734C129.707 109.122 131.932 109.573 134.43 108.954C135.981 108.555 137.324 107.583 138.189 106.233C139.053 104.883 139.376 103.256 139.091 101.678C138.203 101.543 137.306 101.478 136.408 101.483H136.39Z" fill="#205FAE" />
              <path d="M162.706 107.22C162.64 107.218 162.578 107.193 162.529 107.149C162.504 107.126 162.484 107.097 162.47 107.065C162.457 107.033 162.45 106.999 162.45 106.964C162.45 106.929 162.457 106.895 162.47 106.863C162.484 106.831 162.504 106.802 162.529 106.778C168.311 100.528 170.713 95.1349 169.291 91.581C168.82 90.4374 167.96 89.4977 166.864 88.9287C165.947 90.4296 164.48 91.5117 162.776 91.9434C160.004 92.6065 158.168 91.7666 157.48 90.8383C157.26 90.5858 157.121 90.2732 157.08 89.9408C157.039 89.6084 157.098 89.2714 157.25 88.9729C157.886 87.7706 159.898 87.2048 162.759 87.3463C164.114 87.3943 165.449 87.6941 166.696 88.2303C167.352 87.0647 167.632 85.7247 167.499 84.3934C167.137 81.5379 164.736 79.9112 160.896 79.9112H160.728C160.695 79.9124 160.661 79.9069 160.63 79.8951C160.598 79.8833 160.569 79.8654 160.545 79.8424C160.52 79.8194 160.5 79.7918 160.486 79.7611C160.472 79.7305 160.465 79.6973 160.463 79.6637C160.463 79.5948 160.49 79.5287 160.538 79.4792C160.586 79.4297 160.651 79.4007 160.719 79.3984H160.887C164.966 79.3984 167.623 81.2285 168.011 84.3316C168.149 85.766 167.84 87.2079 167.128 88.4602C168.322 89.0788 169.26 90.0994 169.777 91.3423C171.278 95.0819 168.841 100.678 162.909 107.088C162.89 107.126 162.861 107.158 162.825 107.182C162.79 107.205 162.748 107.218 162.706 107.22ZM161.752 87.8679C158.663 87.8679 157.859 88.9022 157.674 89.247C157.568 89.4591 157.529 89.698 157.56 89.9328C157.592 90.1676 157.693 90.3876 157.85 90.5643C158.521 91.4484 160.269 92.0584 162.644 91.4484C164.2 91.0521 165.544 90.0705 166.396 88.7077C165.221 88.2037 163.965 87.9191 162.688 87.8679H161.752Z" fill="#205FAE" />
              <path d="M156.95 46.4942L146.869 42.6308L149.394 34.1702L140.672 31.076L144.203 21.5368L141.051 20.918C136.521 23.4534 133.147 27.6464 131.636 32.6183C130.125 37.5902 130.594 42.9549 132.945 47.5879C135.297 52.2209 139.348 55.7626 144.249 57.4705C149.15 59.1784 154.522 58.92 159.237 56.7494L149.526 53.611L156.95 46.4942Z" fill="#FF785E" />
              <path d="M182.127 62.593C185.777 61.3997 189.01 59.1839 191.441 56.2079C193.873 53.232 195.402 49.6206 195.848 45.8013C196.294 41.982 195.638 38.1147 193.957 34.6573C192.276 31.1999 189.641 28.2973 186.365 26.293L187.362 29.3519L177.334 30.9255L178.896 40.0315L170.28 41.9234L171.807 52.6295L161.956 49.5352L163.951 59.5783C166.52 61.4976 169.511 62.7728 172.673 63.2973C175.835 63.8218 179.077 63.5803 182.127 62.593Z" fill="#FF785E" />
              <path d="M152.995 47.2451L142.914 43.3817L145.439 34.9211L136.717 31.8269L140.248 22.2877L137.096 21.6777C132.593 24.2268 129.245 28.418 127.751 33.378C126.256 38.3379 126.731 43.684 129.075 48.3022C131.42 52.9204 135.453 56.4546 140.335 58.1681C145.217 59.8816 150.571 59.6424 155.282 57.5004L145.571 54.3619L152.995 47.2451Z" fill="#FFB865" />
              <path d="M179.691 60.675C183.343 59.4848 186.577 57.2714 189.012 54.297C191.446 51.3226 192.979 47.7117 193.427 43.8921C193.876 40.0725 193.222 36.2041 191.543 32.7451C189.864 29.2861 187.231 26.3814 183.955 24.375L184.952 27.4339L174.888 29.0075L176.433 38.1489L167.826 40.0319L169.362 50.7381L159.51 47.6438L161.523 57.7134C164.095 59.6237 167.087 60.8894 170.248 61.4046C173.408 61.9199 176.646 61.6697 179.691 60.675Z" fill="#FFB865" />
              <path d="M170.254 156.277H152.192C151.237 156.23 150.336 155.817 149.676 155.124C149.016 154.43 148.648 153.509 148.648 152.551C148.648 151.593 149.016 150.671 149.676 149.978C150.336 149.284 151.237 148.871 152.192 148.824H170.254C171.209 148.871 172.11 149.284 172.77 149.978C173.43 150.671 173.798 151.593 173.798 152.551C173.798 153.509 173.43 154.43 172.77 155.124C172.11 155.817 171.209 156.23 170.254 156.277ZM152.192 149.337C151.37 149.38 150.596 149.738 150.029 150.336C149.463 150.933 149.147 151.726 149.147 152.551C149.147 153.375 149.463 154.168 150.029 154.766C150.596 155.364 151.37 155.721 152.192 155.764H170.254C171.076 155.721 171.85 155.364 172.416 154.766C172.983 154.168 173.299 153.375 173.299 152.551C173.299 151.726 172.983 150.933 172.416 150.336C171.85 149.738 171.076 149.38 170.254 149.337H152.192Z" fill="white" />
              <path d="M101.617 66.1031C101.584 66.0988 101.551 66.0878 101.522 66.0706C101.493 66.0534 101.468 66.0304 101.448 66.0031C101.428 65.9757 101.414 65.9446 101.407 65.9116C101.4 65.8787 101.399 65.8445 101.405 65.8113C101.67 63.9371 100.699 62.9646 99.578 61.8418C98.0508 60.3212 96.3117 58.5884 97.2827 54.2299C98.2538 49.8715 95.6143 48.5807 92.2862 46.9098C90.1498 45.8401 87.731 44.6289 85.9566 42.3922C83.7232 39.572 84.5265 36.5043 85.3122 33.5515C86.1508 30.3777 86.9453 27.363 84.3146 24.5781C84.2687 24.529 84.2432 24.4642 84.2432 24.3969C84.2432 24.3296 84.2687 24.2648 84.3146 24.2157C84.3626 24.1676 84.4277 24.1406 84.4956 24.1406C84.5635 24.1406 84.6286 24.1676 84.6766 24.2157C87.5015 27.2215 86.6275 30.5014 85.7977 33.6752C85.012 36.6457 84.2705 39.4571 86.3362 42.0651C88.04 44.2134 90.4059 45.4069 92.5157 46.4855C95.9056 48.1829 98.8364 49.6504 97.8124 54.3802C96.9296 58.4558 98.4657 60.0118 99.9664 61.5147C101.141 62.6817 102.244 63.7868 101.935 65.9351C101.926 65.9675 101.91 65.9978 101.889 66.024C101.868 66.0503 101.842 66.0719 101.812 66.0876C101.782 66.1034 101.75 66.1128 101.716 66.1155C101.683 66.1181 101.649 66.1139 101.617 66.1031Z" fill="white" />
              <path d="M75.5926 69.0819H75.4867C75.455 69.0685 75.4263 69.0488 75.4023 69.024C75.3783 68.9992 75.3596 68.9699 75.3473 68.9377C75.3349 68.9055 75.3291 68.8711 75.3304 68.8367C75.3316 68.8022 75.3397 68.7683 75.3543 68.7371C76.4931 66.2263 74.0831 64.7411 71.5318 63.1674C69.2631 61.7706 66.9237 60.3207 67.4269 58.0575C67.4332 58.0235 67.4462 57.9911 67.4653 57.9623C67.4844 57.9334 67.5092 57.9088 67.538 57.8898C67.5669 57.8708 67.5993 57.8578 67.6333 57.8517C67.6673 57.8456 67.7022 57.8464 67.7359 57.8541C67.8006 57.8728 67.8562 57.9146 67.892 57.9717C67.9278 58.0288 67.9414 58.0971 67.9301 58.1636C67.5063 60.0732 69.5985 61.3639 71.8055 62.7254C74.3126 64.2725 77.1463 66.023 75.831 68.9139C75.812 68.9622 75.7793 69.0038 75.737 69.0337C75.6946 69.0635 75.6444 69.0803 75.5926 69.0819Z" fill="white" />
            </g>
            <defs>
              <clipPath id="clip0_580_920">
                <rect width="205" height="195" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <p className='font-plus text-[3.125rem] font-semibold text-[#151515] mt-[2.06rem]'>Oops</p>
          <p className='font-plus text-[1.25rem] font-medium text-[#151515] mt-[1.88rem] text-center'>{admin ? "You have no request." : params.get("trader_email") ? "This trader has no request" :  "You have no request. Go to either the home page or clan page to create and manage your clan"}</p>
        </div>
      }

      {reqPage === 1 && <div className='pb-10 rounded-[1.25rem] bg-white w-[100%] overflow-x-scroll no-scrollbar border border-[#80808080]'>
        <RequestTable traderEmail={params.get("trader_email")} showRequestFailed={showRequestFailed} showRequestSuccess={showRequestSuccess} column={headers} data={data} refresh={refresh} setRefresh={setRefresh} />
      </div>}
    </div>
  )
}

export default Request