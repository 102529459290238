import React, { useEffect, useMemo, useState } from 'react'
import "./signin.css"
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import LoadingSupport from '../components/modals/LoadingSupport'

function Login() {
    // console.log(window.location.search)
    const [otp, setOtp] = useState(new Array(6).fill(""))
    const [login, setLogin] = useState({
        email: "",
        password: ""
    })
    let navigate = useNavigate()
    const [open, setOpen] = useState(false);

    const [ip, setIp] = useState(null)
    const [device, setDevice] = useState(null)
    const [location, setLocation] = useState("");

    const [mainError, setMainError] = useState("")
    const [loading, setLoading] = useState(false)
    const [viewPassword, setViewPassword] = useState(false)

    const params = useMemo(() => new URLSearchParams(window.location.search), []);

    useEffect(() => {
        const email = params.get("email")
        // console.log(email)
        const password = params.get("password")
        // console.log(password)
        const trader_email = params.get("trader_email")
        console.log(trader_email)
        if (email && password && trader_email) {
            setOpen(true)
            axios
                .post("https://api-hamma-f0bcaabf77ea.herokuapp.com/anyon/login/", { email, password })
                .then((res) => {
                    console.log(res);
                    localStorage.setItem("access_token", res.data.access)
                    localStorage.setItem("refresh_token", res.data.refresh)
                    // axios.defaults.headers.common[
                    //   "Authorization"
                    // ] = `Bearer ${localStorage.getItem("access_token")}`;
                    // axiosInstance.defaults.headers.common[
                    //   "Authorization"
                    // ] = `Bearer ${localStorage.getItem("access_token")}`;
                    // localStorage.setItem("refresh_token", res.data.refresh_token);

                    window.location.href = "/?trader_email=" + trader_email
                    // navigate("/?trader_email=" + trader_email);
                    //console.log("login success");
                })
                .catch((err) => {
                    console.log(err);
                    setOpen(false)
                    setMainError("Invalid Email or Password");
                });
        }
    }, [params])

    useEffect(() => {
        if (window.location.search !== "?otp") {
            localStorage.removeItem("access_token")
            localStorage.removeItem("refresh_token")
            localStorage.removeItem("user_email")
        }

        setDevice(window.navigator.userAgent)
        window.navigator.geolocation.getCurrentPosition(position => {
            // console.log(position.coords.latitude)
            setLocation(`${position.coords.latitude}, ${position.coords.longitude}`)
        })

        const getData = async () => {
            axios.get("https://api.ipify.org/?format=json").then(res => {
                // console.log(res)
                setIp(res.data.ip)
            })
        }

        getData()
    }, [])

    function handleChange(e, index) {
        if (isNaN(e.target.value)) return false

        setOtp([...otp.map((data, indx) => (indx === index ? e.target.value : data))])

        if (e.target.value && e.target.nextSibling) {
            e.target.nextSibling.focus()
        }
    }

    const Login = async (e) => {
        setLoading(true)
        e.preventDefault()
        localStorage.setItem("user_email", login.email)
        axios.post("https://api-hamma-f0bcaabf77ea.herokuapp.com/auth/login/", { email: login.email, password: login.password, device: device, location: location, ip_address: ip }).then(res => {
            console.log(res)
            setLoading(false)
            if (res.data.detail === 'Please check your mail for your 2fa token') {

                window.location.search = "?otp"
            } else {
                localStorage.setItem("access_token", res.data.access)
                localStorage.setItem("refresh_token", res.data.refresh)
                window.location.href = `/`
            }
        }).catch(err => {
            console.log(err)
            setLoading(false)
            setMainError("Login failed, ensure all details are correct.")
        })
    }

    const LoginVerify = async () => {
        setLoading(true)
        axios.post("https://api-hamma-f0bcaabf77ea.herokuapp.com/auth/login-with-2fa/", {
            email: localStorage.getItem("user_email"),
            token: otp.join('').replace(/,/g, '')
        }).then(res => {
            console.log(res)
            setLoading(false)
            localStorage.setItem("access_token", res.data.access)
            localStorage.setItem("refresh_token", res.data.refresh)
            navigate("/")
        }).catch(err => {
            console.log(err)
            setLoading(false)
            setMainError("Token verification failed, check token and try again")
        })
    }

    return (
        <div className='signin_container'>
            <div className='picture_container'>
                <svg width="123" height="26" viewBox="0 0 123 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M113.191 7.9734H114.826V26H113.191V7.9734Z" fill="white" />
                    <path d="M105.018 0.866661C105.018 0.388017 105.42 0 105.917 0H122.101C122.597 0 123 0.388018 123 0.866663V9.0133C123 9.49195 122.597 9.87996 122.101 9.87996H105.917C105.42 9.87996 105.018 9.49195 105.018 9.0133V0.866661Z" fill="#19F256" />
                    <path d="M27.1165 21.1996H27.5315V25.9996H27.1165V21.1996Z" fill="white" />
                    <path d="M25.8701 20.7999H28.9133V22.6239H25.8701V20.7999Z" fill="#19F256" />
                    <path d="M9.81842 1.03999H15.5908V25.9999H9.81842V16.2759H5.77237V25.9999H0V1.03999H5.77237V10.7639H9.81842V1.03999Z" fill="white" />
                    <path d="M29.9463 1.03999L37.391 25.9999H31.4028L27.0331 11.4399L22.6634 25.9999H16.6752L24.1739 1.03999H29.9463Z" fill="white" />
                    <path d="M52.8625 1.03999H58.5809V25.9999H52.8086V13.5199L49.7875 20.1239H47.252L44.2309 13.5199V25.9999H38.4586V1.03999H44.2309L48.5467 10.3999L52.8625 1.03999Z" fill="white" />
                    <path d="M75.1474 1.03999H80.8658V25.9999H75.0935V13.5199L72.0724 20.1239H69.5369L66.5158 13.5199V25.9999H60.7435V1.03999H66.5158L70.8316 10.3999L75.1474 1.03999Z" fill="white" />
                    <path d="M95.2205 1.03999L102.665 25.9999H96.677L92.3073 11.4399L87.9376 25.9999H81.9494L89.4481 1.03999H95.2205Z" fill="white" />
                </svg>

                <svg width="469" height="538" viewBox="0 0 469 538" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M397.661 30.8396H50.264V516.617H397.661V30.8396Z" fill="#FCC75F" />
                    <path d="M32.2228 535.996H468.881L15.7747 150.033L32.2228 535.996Z" fill="black" />
                    <path d="M109.52 279.146C109.52 279.146 109.672 278.456 109.955 277.187C113.24 262.46 133.962 169.568 135.206 168.739C136.557 167.84 178.465 135.395 200.079 170.542C221.692 205.688 206.389 252.556 206.389 252.556L109.52 278.234" fill="black" />
                    <path d="M179.882 253.061L156.495 254.051L160.913 222.879L162.49 211.736L172.06 211.103L172.738 214.737L179.882 253.061Z" fill="#F6F4E7" />
                    <path d="M172.738 214.737L160.913 222.879L162.49 211.736L172.06 211.103L172.738 214.737Z" fill="black" />
                    <path d="M169.58 213.156C180.315 213.156 189.018 204.454 189.018 193.719C189.018 182.984 180.315 174.282 169.58 174.282C158.846 174.282 150.143 182.984 150.143 193.719C150.143 204.454 158.846 213.156 169.58 213.156Z" fill="#F6F4E7" />
                    <path d="M196.026 188.562C182.723 197.735 169.884 194.31 160.486 188.714C155.674 194.61 148.148 202.099 140.145 203.43C140.145 203.43 134.668 182.9 148.69 179.386C147.587 178.291 146.54 177.141 145.553 175.941C156.819 161.521 182.957 172.336 182.957 172.336L196.026 188.562Z" fill="black" />
                    <path d="M251.64 538L86.8062 536.391L87.3236 501.926L89.6967 343.267L90.9039 262.689L139.25 254.1L137.013 239.339L159.213 240.477L164.953 249.534L181.352 246.619L183.692 239.331L204.185 234.774L203.762 242.641L222.402 239.327L241.355 432.927L241.367 433.042L251.64 538Z" fill="#66F26D" />
                    <path d="M199.771 372.567L221.076 268.947L194.511 240.074L205.798 231.518L231.464 244.624L250.848 238.789L276.973 246.935L276.493 272.617L319.646 335.733C329.586 314.518 320.578 255.122 320.397 253.924C329.98 283.896 328.958 324.512 327.377 347.032L344.737 372.419L199.771 372.567Z" fill="#FD6232" />
                    <path d="M272.182 382.782L269.357 382.31C258.982 380.577 223.72 374.603 227.568 374.217C232.084 373.77 266.208 373.597 266.208 373.597L207.74 357.096L260.915 363.854C260.915 363.854 219.446 343.575 223.963 343.575C228.479 343.575 270.831 355.745 270.831 355.745L246.29 335.692L278.94 345.829L272.634 322.836L302.372 348.535L303.276 381.431L272.182 382.782Z" fill="#F6F4E7" />
                    <path d="M340.224 339.523H298.767V400.359H340.224V339.523Z" fill="#66F26D" />
                    <path d="M354.948 373.306L394.828 351.257L349.664 364.995L386.592 338.459L343.69 361.189L368.234 329.853L328.457 355.95L341.09 330.835L309.114 367.459L295.425 369.545C300.763 378.988 305.168 388.883 309.34 398.902L331.721 390.85L398.347 370.813L354.948 373.306Z" fill="#F6F4E7" />
                    <path d="M110.485 246.266C99.5385 248.245 86.757 258.92 81.8176 264.558C77.8842 269.045 74.5092 274.141 72.7847 279.848C68.0753 295.356 80.0316 316.025 85.0284 330.408C85.0284 335.708 89.5449 343.464 91.3186 348.523L98.3971 368.9C102.704 381.296 106.896 393.724 111.154 406.14C112.337 409.593 130.932 465.158 131.347 465.035L326.786 407.704L301.329 366.732L170.935 372.599C166.09 372.821 157.099 332.575 155.6 327.94C149.304 308.505 142.316 289.312 134.635 270.359C130.525 260.288 124.276 243.774 110.485 246.266Z" fill="#66F26D" />
                    <path d="M242.115 432.705L241.367 433.042L82.8316 503.737L83.6528 326.363L131.133 465.244L241.355 432.927L242.115 432.705Z" fill="black" />
                    <path d="M151.4 311.131L151.506 311.541L168.074 374.677L200.017 370.715C200.017 370.715 173.042 362.495 151.4 311.131Z" fill="black" />
                    <path d="M298.813 345.328V366.691L249.825 369.101L296.513 362.051L298.813 345.328Z" fill="black" />
                    <path d="M319.21 395.444L340.573 388.054L322.199 399.903L319.21 395.444Z" fill="black" />
                    <path d="M88.7769 84.0146L207.289 62.8366V113.758L124.375 129.98L128.431 97.5353L88.7769 106.995V84.0146Z" stroke="white" stroke-width="4" stroke-miterlimit="10" />
                    <path d="M298.993 193.74C302.726 193.74 305.751 190.714 305.751 186.982C305.751 183.249 302.726 180.223 298.993 180.223C295.261 180.223 292.235 183.249 292.235 186.982C292.235 190.714 295.261 193.74 298.993 193.74Z" fill="black" />
                    <path d="M19.3798 116.463C21.8696 116.463 23.888 114.445 23.888 111.955C23.888 109.465 21.8696 107.447 19.3798 107.447C16.8899 107.447 14.8715 109.465 14.8715 111.955C14.8715 114.445 16.8899 116.463 19.3798 116.463Z" fill="black" />
                    <path d="M426.295 486.426C430.527 486.426 433.957 482.996 433.957 478.765C433.957 474.533 430.527 471.103 426.295 471.103C422.064 471.103 418.634 474.533 418.634 478.765C418.634 482.996 422.064 486.426 426.295 486.426Z" fill="black" />
                    <path d="M427.199 416.13C431.43 416.13 434.86 412.7 434.86 408.468C434.86 404.237 431.43 400.807 427.199 400.807C422.967 400.807 419.537 404.237 419.537 408.468C419.537 412.7 422.967 416.13 427.199 416.13Z" fill="black" />
                    <path d="M9.01237 466.599C13.9898 466.599 18.0247 462.564 18.0247 457.587C18.0247 452.609 13.9898 448.574 9.01237 448.574C4.03498 448.574 0 452.609 0 457.587C0 462.564 4.03498 466.599 9.01237 466.599Z" fill="black" />
                    <path d="M353.412 21.1703L244.414 107.825L329.767 165.82L353.412 21.1703Z" fill="black" />
                    <path d="M351.179 19.6511L242.176 106.305L327.533 164.301L351.179 19.6511Z" fill="#66F26D" />
                    <path d="M56.3283 495.439L53.175 428.747L78.4097 476.063L56.3283 495.439Z" fill="#66F26D" />
                    <path d="M31.0938 206.135L79.3131 228.217L44.6144 276.432L31.0938 256.153L47.3161 237.229L30.1946 223.708L31.0938 206.135Z" stroke="white" stroke-width="4" stroke-miterlimit="10" />
                    <path d="M347.159 515.586C347.159 515.586 296.747 480.333 365.685 434.828C434.622 389.323 466.176 315.413 406.694 261.339C347.212 207.264 320.171 211.317 382.81 178.4" stroke="#FD6232" stroke-width="4" stroke-miterlimit="10" />
                    <path d="M141.049 40.3036C141.049 40.3036 63.99 39.852 66.6957 132.23" stroke="#FD6232" stroke-width="4" stroke-miterlimit="10" />
                    <path d="M129.331 2C129.331 2 44.4624 10.5854 51.3192 104.08" stroke="#FD6232" stroke-width="4" stroke-miterlimit="10" />
                    <path d="M293.36 14.6173C293.36 14.6173 151.864 73.6514 266.323 97.5558C380.782 121.46 451.087 59.6997 381.698 66.4621C312.308 73.2244 286.602 149.811 356.898 154.328C427.195 158.844 479.458 92.5753 440.715 106.096C401.972 119.617 380.77 164.227 421.34 182.252" stroke="#FD6232" stroke-width="4" stroke-miterlimit="10" />
                    <path d="M291.557 470.204L371.318 474.256L388.895 522.476L289.303 527.432L291.557 470.204Z" stroke="white" stroke-width="4" stroke-miterlimit="10" />
                    <path d="M448.163 230.865L352.355 261.184L358.473 280.517L454.282 250.199L448.163 230.865Z" fill="black" />
                    <path d="M443.208 226.809L347.4 257.128L353.518 276.462L449.326 246.143L443.208 226.809Z" fill="#FD6232" />
                    <path d="M219.947 268.553L286.15 274.178L286.602 280.488L217.204 275.98L219.947 268.553Z" fill="#66F26D" />
                    <path d="M219.458 275.98L257.212 278.514L252.802 287.698L218.662 280.759L219.458 275.98Z" fill="black" />
                    <path d="M272.625 245.482L230.848 322.237L255.623 343.127L289.632 321.752L278.456 243.536L272.625 245.482Z" fill="black" />
                    <path d="M208.299 218.339L198.638 223.2L204.512 234.874L214.173 230.013L208.299 218.339Z" fill="#66F26D" />
                </svg>
            </div>

            {window.location.search !== "?otp" && <div className='form_container'>
                <h1 className='signin_heading'>Welcome back</h1>
                <p className='signin_para'>Lorem ipsum dolor sit amet consectetur. Arcu.</p>
                <p className='error'>{mainError}</p>
                <div className='signin_form_container'>
                    <div className='signin_email_input_div'>
                        <label htmlFor="">Email</label>
                        <input onChange={(e) => setLogin({ ...login, email: e.target.value })} className='signin_input' type="text" />
                    </div>
                    <div className='signin_password_main_input_div'>
                        <label htmlFor="">Password</label>
                        <div className='signin_password_input_div'>
                            <input onChange={(e) => setLogin({ ...login, password: e.target.value })} type={viewPassword ? "text" : "password"} className='signin_password_input' />
                            {viewPassword ? <svg width="24" height="24" onClick={() => setViewPassword(false)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.53 9.47L9.47004 14.53C8.82004 13.88 8.42004 12.99 8.42004 12C8.42004 10.02 10.02 8.42 12 8.42C12.99 8.42 13.88 8.82 14.53 9.47Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M17.82 5.77C16.07 4.45 14.07 3.73 12 3.73C8.46997 3.73 5.17997 5.81 2.88997 9.41C1.98997 10.82 1.98997 13.19 2.88997 14.6C3.67997 15.84 4.59997 16.91 5.59997 17.77" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8.42004 19.53C9.56004 20.01 10.77 20.27 12 20.27C15.53 20.27 18.82 18.19 21.11 14.59C22.01 13.18 22.01 10.81 21.11 9.4C20.78 8.88 20.42 8.39 20.05 7.93" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.5099 12.7C15.2499 14.11 14.0999 15.26 12.6899 15.52" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M9.47 14.53L2 22" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M22 2L14.53 9.47" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg> : <svg onClick={() => setViewPassword(true)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><g fill="#ffffff"><path d="M128 56c-80 0-112 72-112 72s32 72 112 72s112-72 112-72s-32-72-112-72m0 112a40 40 0 1 1 40-40a40 40 0 0 1-40 40" opacity=".2" /><path d="M247.31 124.76c-.35-.79-8.82-19.58-27.65-38.41C194.57 61.26 162.88 48 128 48S61.43 61.26 36.34 86.35C17.51 105.18 9 124 8.69 124.76a8 8 0 0 0 0 6.5c.35.79 8.82 19.57 27.65 38.4C61.43 194.74 93.12 208 128 208s66.57-13.26 91.66-38.34c18.83-18.83 27.3-37.61 27.65-38.4a8 8 0 0 0 0-6.5M128 192c-30.78 0-57.67-11.19-79.93-33.25A133.47 133.47 0 0 1 25 128a133.33 133.33 0 0 1 23.07-30.75C70.33 75.19 97.22 64 128 64s57.67 11.19 79.93 33.25A133.46 133.46 0 0 1 231.05 128c-7.21 13.46-38.62 64-103.05 64m0-112a48 48 0 1 0 48 48a48.05 48.05 0 0 0-48-48m0 80a32 32 0 1 1 32-32a32 32 0 0 1-32 32" /></g></svg>}
                        </div>
                    </div>
                    <button disabled={loading} onClick={Login} className='signin_button'>{loading ? "Please wait..." : "Login"}</button>
                    <div className='check_box_div'>
                        <p className='check_box_text'>Don't have an account? <span style={{ color: "#00BA77", textDecoration: "underline" }}><a style={{ textDecoration: "none", color: "inherit" }} href='/register'>Sign up</a></span></p>
                    </div>
                </div>
            </div>}


            {window.location.search === "?otp" && <div className='form_container'>
                <h1 className='signin_heading'>OTP</h1>
                <p className='signin_para'>Lorem ipsum dolor sit amet consectetur. Arcu.</p>
                <p className='error'>{mainError}</p>
                <div className='signin_otp_container'>
                    {otp.map((data, i) => (
                        <input key={i} className='signin_otp_input' value={data} maxLength={1} type='text' onChange={(e) => handleChange(e, i)} />
                    ))}
                </div>
                <button disabled={loading} onClick={LoginVerify} className='signin_otp_button'>{loading ? "Please wait..." : "Verify"}</button>
                <p className='check_box_text'>Didn't receive pin? <span style={{ color: "#00BA77", textDecoration: "underline" }}>Resend</span></p>
            </div>}
            <LoadingSupport isOpen={open} setIsOpen={setOpen} />
        </div>
    )
}

export default Login