import React, { useMemo } from "react";
import CalendarHeatmap from "react-calendar-heatmap";
import "react-calendar-heatmap/dist/styles.css";
import "../GitGraphs.css";
import axios from "../axios";

function TradingDays() {
    const [info, setInfo] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [val, setVal] = React.useState("");
    const params = useMemo(() => new URLSearchParams(window.location.search), []);

    React.useEffect(() => {
        if(params.get("trader_email")){
            axios
            .get(`/leaderboard/trading-days/support/${params.get("trader_email")}`)
            .then((res) => {
                console.log(res);
                setInfo(
                    res.data.trading_days.map((item) => {
                        return { date: item };
                    })
                );
            })
            .catch((err) => {
                console.log(err);
            });
        }else{

            axios
            .get("/leaderboard/trading-days/")
            .then((res) => {
                console.log(res);
                setInfo(
                    res.data.trading_days.map((item) => {
                        return { date: item };
                    })
                );
            })
            .catch((err) => {
                console.log(err);
            });
        }

    }, [params]);

    let handleClick = (value) => {
        if (value === null) {
            setOpen2(true);
            setVal("no trading record for this day");
        } else {
            setOpen(true);
            setVal(value);
            //console.log(value);
        }
    };

    return (
        <div style={{ margin: "7% 0% 0% 0%" }}>
            <dialog
                onClick={() => {
                    setOpen(false);
                }}
                open={open}
                style={{padding:"10px", border:"1px solid black"}}
            >
                <div>
                    Traded on this day {val.date}
                    <br />
                    <br />
                    <span className="cursor-pointer">Click to close</span>
                </div>
            </dialog>
            <dialog
                onClick={() => {
                    setOpen2(false);
                }}
                open={open2}
                style={{padding:"10px", border:"1px solid black"}}
            >
                <div style={{}}>
                    No trading record for this day
                    <br />
                    <br />
                    <span className="cursor-pointer">Click to close</span>
                </div>
            </dialog>
            <CalendarHeatmap
                startDate={new Date("2024-05-01")}
                endDate={new Date("2025-04-30")}
                values={info}
                onClick={handleClick}
                showWeekdayLabels={true}
            />
        </div>
    );
}

export default TradingDays;