import React from 'react'
import SideMenu from '../components/SideMenu'
import TopMenu from '../components/TopMenu'
import Home from '../components/Home'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Leaderboard from '../components/Leaderboard'
import Clan from '../components/Clan'
import Payout from '../components/Payout'
import Request from '../components/Request'
// import MobileMenu from '../components/modals/MobileMenu'
import Profile from '../components/Profile'
import PaymentHistory from '../components/PaymentHistory'
import { AccContext } from '../context/OtherContext'
import axios from '../axios'

// function toPascalCase(inputString) {
//     // Split the string by spaces or underscores
//     const words = inputString.split(/[\s_]+/);

//     // Capitalize the first letter of each word
//     for (let i = 0; i < words.length; i++) {
//         words[i] = words[i][0].toUpperCase() + words[i].slice(1);
//     }

//     // Join the words back together
//     return words.join('');
// }


function Dashboard() {
    const [searchParams] = useSearchParams();
    const params = useLocation()
    // const link = params.pathname.split("/")[0]
    const query = params.search.split("?")[1]
    const second = query?.split("&")[0] 
  
    console.log(second)
    // console.log(params.search.split("?")[1])
    let navigate = useNavigate();

    const { values, values1, values3, values2, values4 } = React.useContext(AccContext)

    // eslint-disable-next-line no-unused-vars
    const [isAdmin, setIsAdmin] = values
    // eslint-disable-next-line no-unused-vars
    const [compEndDate, setCompEndDate] = values1
    // eslint-disable-next-line no-unused-vars
    const [compStartDate, setCompStartDate] = values3
    // eslint-disable-next-line no-unused-vars
    const [countries, setCountries] = values4
    // eslint-disable-next-line no-unused-vars
    const [globalRefresh, setGlobalRefresh] = values2

    React.useEffect(() => {
        var token = searchParams.get("token");
        var refresh = searchParams.get("refresh_token");
        if (token) {
            localStorage.setItem("access_token", token);
        }

        if (refresh) {
            localStorage.setItem("refresh_token", refresh);
        }

        if (token !== null) {
            //console.log("token is not null");
            return navigate("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {

        axios.get(`leaderboard/join-clan/`).then(res => {
            // console.log(res.data)
            setIsAdmin(res.data[0].admin)
            // setRefresh(!refresh)
            // showRequestSuccess("Successfully sent join request")
        }).catch(err => {
            console.log(err)
            // showRequestFailed("Failed to send join request")
        })

        axios.get("leaderboard/currentcompetition/").then((res) => {
            console.log(res)
            setCompEndDate(res.data.end_date)
            setCompStartDate(res.data.start_date)
            localStorage.setItem("comp_id", res.data.id)
        });

        axios.get("https://restfulcountries.com/api/v1/countries", {
            headers: {
              'Authorization': `Bearer ${"669|oG00DKoL35tVEDM6kA9NUhVTbH13ljE1jSmQPYyg"}`,
              'Content-Type': 'application/json', // Modify content type as needed
            }
          }).then(res => {
            console.log(res)
            const countriesWithFlags = res.data.data.map((country) => {
              return {
                countryName: country.name,
                flagImageUrl: country.href.flag
              };
            });
            console.log(countriesWithFlags);
            setCountries(countriesWithFlags)
          }
          ).catch(err =>
            console.log(err)
          )

        // fetch("https://restcountries.com/v2/all")
        //     .then((response) => response.json())
        //     .then((data) => {
        //         const countriesWithFlags = data.map((country) => {
        //             return {
        //                 countryName: country.name,
        //                 flagImageUrl: country.flags.png
        //             };
        //         });
        //         console.log(countriesWithFlags);
        //         setCountries(countriesWithFlags) // Output the array of objects
        //     })
        //     .catch((error) => {
        //         console.error("Error fetching data:", error);
        //     });
    }, [setCompEndDate, setCompStartDate, setCountries, setIsAdmin, globalRefresh]);

    return (
        <div className='lg:flex'>
            <SideMenu route={(second?.length < 14  && second) || (query?.length < 14 && query) || "home"} />
            <div className='lg:w-[88%] bg-[#F4F4F4] lg:overflow-y-scroll lg:h-[100vh] lg:relative'>
                <TopMenu route={(second?.length < 14  && second) || (query?.length < 14 && query)} />
                {(query === "home" || second === "home" || query === undefined || params.search.split("=")[0] === "?trader_email") && <Home />}
                {(query === "leaderboard" || second === "leaderboard") && <Leaderboard />}
                {(query === "clan" || second === "clan") && <Clan />}
                {(query === "payout" || second === "payout") && <Payout />}
                {(query === "request" || second === "request") && <Request />}
                {(query === "profile" || second === "profile") && <Profile />}
                {(query === "payout-history" || second === "payout-history") && <PaymentHistory />}
            </div>

        </div>
    )
}

export default Dashboard