import { Dialog } from '@headlessui/react'
import React, { useRef, useState } from 'react'
import Badge from "../../assets/Group 268.svg"
import axios from '../../axios';
import { AccContext } from '../../context/OtherContext';
import { useNavigate } from 'react-router-dom';


function CreateClan(props) {
    const navigate = useNavigate()
    const [clanDet, setClanDet] = useState({
        clanName: "",
        clanDesc: ""
    })
    const [inputValue, setInputValue] = useState('');
    const [file, setFile] = useState(null)
    let completeButtonRef = useRef(null)
    const [logoError, setLogoError] = useState("")
    const [clanDescErro, setclanDescErro] = useState("")
    const [clanNameError, setClanNameError] = useState("")
    const [commLinkError, setCommLinkError] = useState("")
    const [canCreate, setCanCreate] = useState({
        can: false,
        reason: ""
    })
    const { values2 } = React.useContext(AccContext)
    const [globalRefresh, setGlobalRefresh] = values2
    const [disable, setDisable] = useState(false)
    const [imageBase64, setImageBase64] = useState('');

    console.log(file)

    const handlePaste = () => {
        // console.log("Paste has been clicked")
        // Implement the paste functionality here
        // You can access the clipboard data using the Clipboard API
        navigator.clipboard.readText().then((text) => {
            setInputValue(text);
        });
    };

    function handleFileUpload(event) {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
    
          reader.onload = (e) => {
            const base64String = e.target.result;
            setImageBase64(base64String);
          };
    
          reader.readAsDataURL(file);
          setFile(file);
        }
    };

    const createClan = async () => {
        setDisable(true)
        if (file === null) {
            setLogoError("Required")
        } else if (clanDet.clanName === "") {
            setClanNameError("Clan name is required")
        } else if (clanDet.clanDesc === "") {
            setclanDescErro("Clan description is required")
        } else if (inputValue === "") {
            setCommLinkError("Community link is required")
        } else {

            let formdata = new FormData();
            formdata.append("clan_logo", file);
            formdata.append("clan_name", clanDet.clanName);
            formdata.append("community_link", inputValue);
            formdata.append("description", clanDet.clanDesc);
            // console.log(formdata.get("image"))

            axios.post("/leaderboard/create-clan/", formdata, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }).then(res => {
                console.log(res)

                setClanDet({
                    clanName: "",
                    clanDesc: ""
                })
                setInputValue("")
                setFile(null)
                setDisable(false)
                props.setRefresh(!props.refresh)
                setGlobalRefresh(!globalRefresh)
                props.showRequestSuccess("Successfully sent request to create clan.")
                props.setIsOpen(!props.isOpen)
                navigate("/?clan")
            }).catch(err => {
                console.log(err)
                setDisable(false)
                setClanDet({
                    clanName: "",
                    clanDesc: ""
                })
                setInputValue("")
                setFile(null)
                props.showRequestFailed("Failed to create clan.")
                props.setIsOpen(!props.isOpen)
            })

        }
    }

    React.useEffect(() => {
        axios.get("/leaderboard/can-create-join/").then(res => {
            console.log(res)
            setCanCreate({ can: res.data.status, reason: res.data.reason })

        }).catch(err => {
            console.log(err)
        })
    }, [props.isOpen])

    return (

        <Dialog
            open={props.isOpen}
            initialFocus={completeButtonRef}
            onClose={() => props.setIsOpen(false)}
            className="relative z-50"
            as="div"
        >

            {/* The backdrop, rendered as a fixed sibling to the panel container */}
            <div className="fixed inset-0 bg-[#15151566]" aria-hidden="true" />

            {/* Full-screen container to center the panel */}
            <div className="fixed inset-0 flex items-center justify-center">
                {/* The actual dialog panel  */}

                <Dialog.Panel className="mx-auto lg:w-[43.75rem] w-[95%] bg-white rounded-[1.25rem]">
                    <div className="">
                        <div className=' flex items-center justify-center py-[1.5rem] gap-[0.62rem] lg:border-b lg:border-[#80808080]'>
                            <img src={Badge} alt="" />
                            <p className='font-plus text-[1.25rem] font-bold text-[#000000]'>Create a Clan</p>
                        </div>
                        <div className='flex flex-col lg:flex-row'>
                            <div className='px-[2.5rem] pt-[1.88rem] lg:border-r border-[#80808080] border-b lg:border-b-0 flex flex-col items-center'>
                                <label htmlFor='avatar_img' className='cursor-pointer mt-[0.62rem] font-plus text-[0.75rem] font-medium text-[#000000] mb-[1.63rem] lg:mb-0'>
                                    {file ? <img src={imageBase64} alt='' className='w-[86px] h-[86px] rounded-full'/> : <svg xmlns="http://www.w3.org/2000/svg" width="86" height="86" viewBox="0 0 86 86" fill="none">
                                        <circle cx="43" cy="43" r="43" fill="#D9D9D9" />
                                    </svg>}
                                    <p>Change Avatar</p>
                                </label>
                                <input onChange={e => {
                                    setLogoError("")
                                    setDisable(false)
                                    handleFileUpload(e)
                                }} id='avatar_img' accept='image/png, image/jpeg' type="file" className='hidden' />
                                <p className='text-red-500 text-center'>{logoError}</p>
                            </div>
                            <div className='pb-[3rem] pt-[1.87rem] pl-[1rem] pr-[1rem] flex flex-col gap-[1.25rem]'>
                                <div className='flex flex-col'>
                                    <label htmlFor="" className='font-plus text-[0.75rem] font-medium text-[#000000] mb-[0.62rem]'>Clan Name</label>
                                    <input type="text" onChange={(e) => {
                                        setClanNameError("")
                                        setDisable(false)
                                        setClanDet({ ...clanDet, clanName: e.target.value })
                                    }} className='outline-[#00C500] border border-[#80808080] rounded-[0.625rem] pl-[1rem] py-[0.7rem] font-plus' />
                                    <p className='text-red-500 text-center'>{clanNameError}</p>
                                </div>
                                <div className='flex flex-col'>
                                    <label htmlFor="" className='font-plus text-[0.75rem] font-medium text-[#000000] mb-[0.62rem]'>Description</label>
                                    <textarea onChange={(e) => {
                                        setclanDescErro("")
                                        setDisable(false)
                                        setClanDet({ ...clanDet, clanDesc: e.target.value })

                                    }} name="" id="" cols="40" rows="2" className='outline-[#00C500] border border-[#80808080] rounded-[0.625rem] pl-[1rem] py-[0.7rem] font-plus'></textarea>
                                    <p className='text-red-500 text-center'>{clanDescErro}</p>
                                </div>
                                <div className='flex flex-col'>
                                    <label htmlFor="" className='font-plus text-[0.75rem] font-medium text-[#000000] mb-[0.62rem]'>Community link</label>
                                    <div className='flex'>
                                        <input value={inputValue} onChange={e => {
                                            setCommLinkError("")
                                            setDisable(false)
                                            setInputValue(e.target.value)
                                        }} placeholder='Eg. https://google.com' type="text" className='outline-[#00C500] border border-[#80808080] rounded-l-[0.625rem] pl-[1rem] py-[0.7rem] font-plus w-[18rem]' />
                                        <button onClick={handlePaste} className='bg-[#00C500] flex items-center gap-[0.31rem] rounded-r-[0.625rem] px-[1.31rem]'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0625 6.125C10.0625 5.54488 9.83194 4.98837 9.422 4.578C9.01162 4.16806 8.45512 3.9375 7.875 3.9375C6.57256 3.9375 4.80244 3.9375 3.5 3.9375C2.91988 3.9375 2.36338 4.16806 1.953 4.578C1.54306 4.98837 1.3125 5.54488 1.3125 6.125C1.3125 7.42744 1.3125 9.19756 1.3125 10.5C1.3125 11.0801 1.54306 11.6366 1.953 12.047C2.36338 12.4569 2.91988 12.6875 3.5 12.6875C4.80244 12.6875 6.57256 12.6875 7.875 12.6875C8.45512 12.6875 9.01162 12.4569 9.422 12.047C9.83194 11.6366 10.0625 11.0801 10.0625 10.5V6.125Z" fill="white" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.98145 3.0625H7.87476C8.6872 3.0625 9.46595 3.38494 10.0404 3.95938C10.6148 4.53381 10.9373 5.31256 10.9373 6.125V10.0183C11.3542 9.933 11.7409 9.72738 12.0468 9.422C12.4567 9.01162 12.6873 8.45512 12.6873 7.875C12.6873 6.57256 12.6873 4.80244 12.6873 3.5C12.6873 2.91988 12.4567 2.36338 12.0468 1.953C11.6364 1.54306 11.0799 1.3125 10.4998 1.3125C9.19732 1.3125 7.4272 1.3125 6.12476 1.3125C5.54463 1.3125 4.98813 1.54306 4.57776 1.953C4.27238 2.25881 4.06676 2.64556 3.98145 3.0625Z" fill="white" />
                                            </svg>
                                            <p className='font-plus text-white font-medium text-[0.75rem]'>Paste</p>
                                        </button>
                                    </div>
                                    <p className='text-red-500 text-center'>{commLinkError}</p>
                                </div>
                                {canCreate.can ?
                                    <button disabled={disable} onClick={createClan} className='bg-[#00C500] text-white font-plus font-semibold text-[0.75rem] rounded-[0.3125rem] py-[0.81rem] w-[6.625rem]'>{disable ? "Please wait..." : "Create Clan"}</button>
                                    : <p className='font-plus text-red-500 text-center'>{canCreate.reason}</p>}
                            </div>
                        </div>

                        <p
                            ref={completeButtonRef}
                            onClick={() => props.setIsOpen(false)}
                            className="hidden font-poppins text-[#BE8688] text-[1.25rem] font-medium"
                        >
                        </p>



                    </div>
                </Dialog.Panel>

            </div>
        </Dialog>

    )
}

export default CreateClan