import { Dialog } from '@headlessui/react'
import React, { useRef } from 'react'
import axios from '../../axios'
// import Badge from "../../assets/Group 268.svg"

function JoinCompetition(props) {
    let completeButtonRef = useRef(null)
    // console.log(props.accounts)

    const joinCompetition = async (number) => {
        // console.log(number)
        // console.log(localStorage.getItem("comp_id"))
        if (props.clanIdAvailable) {

            axios.post(`leaderboard/join-competition/${localStorage.getItem("comp_id")}/`, { account_number: number }).then(res => {
                console.log(res)
                props.setRefresh(!props.refresh)
                props.showRequestSuccess(res.data.detail)
                props.setIsOpen(!props.isOpen)
            }).catch(err => {
                console.log(err)
                props.showRequestFailed("Failed to join competition")
            })
        } else {
            props.showRequestFailed("You need to join a clan before you join a competition")
        }
    }

    return (

        <Dialog
            open={props.isOpen}
            initialFocus={completeButtonRef}
            onClose={() => props.setIsOpen(false)}
            className="relative z-50"
            as="div"
        >
            {/* The backdrop, rendered as a fixed sibling to the panel container */}
            <div className="fixed inset-0 bg-[#15151566]" aria-hidden="true" />

            {/* Full-screen container to center the panel */}
            <div className="fixed inset-0 flex items-center justify-center">
                {/* The actual dialog panel  */}

                <Dialog.Panel className="mx-auto lg:w-[50.75rem] w-[95%] lg:h-[27.5rem] h-[25rem] overflow-y-scroll bg-white rounded-[1.25rem]">
                    <div className=" p-[1rem]">
                        {props.accounts.length > 0 && <h1 className='text-center font-plus text-[1.5rem] font-bold mb-[1rem]'>Available accounts you can join the competition with</h1>}
                        {props.accounts.length > 0 ?
                            <div className='flex flex-wrap gap-[1rem]'>

                                {props.accounts.map(acc => (
                                    <div key={acc.id} className='border border-[#20CC20] p-[1rem] flex flex-col'>
                                        <p className='font-plus text-[#151515] text-[1rem] font-medium'>Account Number: <span className='text-[#20CC20]'>{acc.acc.number}</span></p>
                                        <p className='font-plus text-[#151515] text-[1rem] font-medium'>Type of account: <span className='text-[#20CC20]'>{acc.acc.typeofaccount}</span></p>
                                        <p className='font-plus text-[#151515] text-[1rem] font-medium'>Account size: <span className='text-[#20CC20]'>{acc.acc.acc_size}</span></p>
                                        <p className='font-plus text-[#151515] text-[1rem] font-medium'>Broker: <span className='text-[#20CC20]'>{acc.acc.platform}</span></p>
                                        <button onClick={() => joinCompetition(acc.acc.number)} className='bg-[#20CC20] text-white font-plus font-medium rounded-lg py-[0.2rem] mt-[1rem]'>Join Competition</button>
                                    </div>
                                ))}
                            </div>
                            : <p className='font-plus text-center lg:mt-[8rem] mt-[5rem] text-gray-400 font-semibold text-[2rem]'>No valid account available, log into ft9ja.com to purchase an account</p>}

                        <p
                            ref={completeButtonRef}
                            onClick={() => props.setIsOpen(false)}
                            className="hidden font-poppins text-[#BE8688] text-[1.25rem] font-medium"
                        >
                        </p>



                    </div>
                </Dialog.Panel>

            </div>
        </Dialog>

    )
}

export default JoinCompetition