import React from 'react'
import axios from '../../axios';
import { AccContext } from '../../context/OtherContext';


function convertDate(timestamp) {
    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based, so add 1 and pad with 0 if needed.
    const day = date.getDate().toString().padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
}

function RequestTable(props) {
    const {values2 } = React.useContext(AccContext)
    const [globalRefresh, setGlobalRefresh] = values2
    const acceptRequest = async (id) => {
        console.log(id)
        try {
            axios.put("/leaderboard/join-clan/", {
                request_id: id,
                action: "approved"
            }).then(res => {
                console.log(res)
                props.setRefresh(!props.refresh)
                setGlobalRefresh(!globalRefresh)
                props.showRequestSuccess("Successfully accepted request to join clan")
            }).catch(err => {
                console.log(err)
                props.showRequestFailed("Failed to accept request to join clan")
            })
        } catch (error) {
            console.log(error)
        }
    }

    const rejectRequest = async (id) => {
        console.log(id)
        try {
            axios.put("/leaderboard/join-clan/", {
                request_id: id,
                action: "rejected"
            }).then(res => {
                console.log(res)
                props.setRefresh(!props.refresh)
                setGlobalRefresh(!globalRefresh)
                props.showRequestSuccess("Successfully rejected request to join clan")
            }).catch(err => {
                console.log(err)
                props.showRequestFailed("Failed to reject request to join clan")
            })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <table className="w-[100%] border-collapse bg-white rounded-[1.25rem] ">

            <thead className="rounded-[1.25rem]">
                <tr className="w-[100%]">
                    {props.column.map((row) => (
                        <td
                            className={`text-center py-[1rem] font-plus ${row.key === "total-profit" ? "text-[#00C500]" : "text-black"} text-[0.875rem] font-semibold`}
                            key={row.key}
                        >
                            {row.label}
                        </td>
                    ))}
                </tr>
            </thead>
            <tbody className="w-[100%]">
                {props.data.map((item, index) => (
                    <tr key={item.id} className="border-y border-[#80808080]">
                        <td className="lg:text-center whitespace-nowrap py-[1.5rem] lg:px-[0.5rem] px-[1rem] font-plus text-[0.875rem] font-medium text-black">
                            {item.user_name}
                        </td>
                        <td className="lg:text-center whitespace-nowrap py-[1rem] lg:px-[0.5rem] px-[1rem] font-plus text-[0.875rem] font-medium text-black">
                            Requested to join clan
                        </td>
                        <td className="lg:text-center whitespace-nowrap py-[1rem] lg:px-[0.5rem] px-[1rem] font-plus text-[0.875rem] font-medium text-black">
                            {convertDate(item.date_request)}
                        </td>
                        {!props.traderEmail && <td className='flex justify-center gap-[0.62rem] pt-[1rem]'>
                            <button onClick={() => acceptRequest(item.id)} className='font-plus text-[0.875rem] font-semibold text-white bg-[#00C500] py-[0.44rem] px-[1.88rem] rounded-[0.3125rem]'>Accept</button>
                            <button onClick={() => rejectRequest(item.id)} className='font-plus text-[0.875rem] font-semibold text-white bg-[#FF2626] py-[0.44rem] px-[1.88rem] rounded-[0.3125rem]'>Decline</button>
                        </td>}

                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default RequestTable