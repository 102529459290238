import { Dialog } from '@headlessui/react'
import React, { useEffect, useRef, useState } from 'react'
import axios from '../../axios'
import { AccContext } from '../../context/OtherContext';

function ConvertData(params) {
    const date = new Date(params);

    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    };

    const formattedDate = date.toLocaleString("en-US", options);
    return formattedDate
}

function NotifModal(props) {
    let completeButtonRef = useRef(null)
    const [data, setData] = useState([])
    const [ids, setIds] = useState([])
    const [refresh, setRefresh] = useState(false)
    const { values2 } = React.useContext(AccContext)
    // eslint-disable-next-line no-unused-vars
    const [globalRefresh, setGlobalRefresh] = values2
    // console.log(ids)

    useEffect(() => {
        axios.get("leaderboard/notifications/").then((res) => {
            console.log(res)
            if(res.data.length > 0){
                setData(res.data)
                setIds(res.data.map(notif => notif.notification_id))
            }else{
                setData([])
            }

        }).catch(err => {
            console.log(err)
        });
    }, [refresh, globalRefresh])

    const markNotifsAsRead = async () => {
        axios.put("leaderboard/notifications/", {id: ids}).then((res) => {
            console.log(res)
            setRefresh(!refresh)
            setGlobalRefresh(!globalRefresh)
        }).catch(err => {
            console.log(err)
        });
    }

    return (

        <Dialog
            open={props.isOpen}
            initialFocus={completeButtonRef}
            onClose={() => props.setIsOpen(false)}
            className="relative z-50"
            as="div"
        >
            {/* The backdrop, rendered as a fixed sibling to the panel container */}
            <div className="fixed inset-0 bg-[#15151566]" aria-hidden="true" />

            {/* Full-screen container to center the panel */}
            <div className="fixed inset-0 flex items-center justify-center">
                {/* The actual dialog panel  */}

                <Dialog.Panel className="mx-auto lg:w-[25.75rem] w-[95%] pb-10 absolute top-[3.13rem] overflow-y-scroll no-scrollbar lg:right-[5.19rem] h-[15.3125rem] bg-white rounded-b-[1.25rem] rounded-tl-[1.25rem]">
                    <div className="h-[100%]">
                        <div className="flex flex-col justify-between pt-10">
                            <p
                                ref={completeButtonRef}
                                onClick={() => props.setIsOpen(false)}
                                className="hidden font-poppins text-[#BE8688] text-[1.25rem] font-medium"
                            >
                                close
                            </p>
                            <div className='flex items-center justify-between pl-[1.12rem] pr-[1.73rem]'>
                                <p className='font-plus text-[1.125rem] font-semibold text-[#000000]'>Notifications</p>
                                <div className='flex items-center gap-[0.75rem] '>
                                    <p onClick={markNotifsAsRead} className='font-plus text-[0.75rem] font-normal text-[#00000080] cursor-pointer'>Mark all as read</p>
                                    <svg onClick={() => props.setIsOpen(false)} xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                                        <rect width="17.3333" height="16" rx="3" fill="#D9D9D9" fill-opacity="0.5" />
                                        <path d="M11.3337 5.33301L6.00037 10.6663" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M6.00037 5.33301L11.3337 10.6663" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </div>
                            <div className='flex gap-[1rem] flex-col pl-[1.12rem] pr-[2.56rem] mt-[2.44rem] pb-[1rem]'>
                                {data?.map(dat => (
                                    <div key={dat.notification_id} className='flex items-start gap-[1rem]'>
                                        {dat.is_read && <svg xmlns="http://www.w3.org/2000/svg" width="15" height="8" viewBox="0 0 8 8" fill="none" className='mt-2'>
                                            <circle cx="4" cy="4" r="4" fill="#359602" />
                                        </svg>}
                                        <div className={`${!dat.is_read && "ml-[1.5rem]"} border-b border-[#D9D9D9] pb-[1rem] w-[90%]`}>
                                            <p className='font-plus text-[#434343] text-[0.875rem] font-normal mb-[0.75rem]'>{dat.message}</p>
                                            <p className='font-plus text-[#43434380] text-[0.75rem] font-normal'>{ConvertData(dat.timestamp)}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {data.length <= 0 && <p className='text-center font-plus font-bold text-gray-400 text-[1.5rem]'>There are no notifications at the moment.</p>}

                        </div>
                    </div>
                </Dialog.Panel>

            </div>
        </Dialog>

    )
}

export default NotifModal