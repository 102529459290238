import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

const menu = [
    {
        id: 1,
        title: "Home",
        link: "home" || undefined
    },
    {
        id: 2,
        title: "Leaderboard",
        link: "leaderboard"
    },
    {
        id: 3,
        title: "Clan",
        link: "clan"
    },
    {
        id: 4,
        title: "Payout",
        link: "payout"
    },
    {
        id: 5,
        title: "Request",
        link: "request"
    },
]

const supportmenu = [
    {
        id: 1,
        title: "Home",
        link: "home" || undefined
    },
    {
        id: 2,
        title: "Leaderboard",
        link: "leaderboard"
    },
    {
        id: 3,
        title: "Clan",
        link: "clan?details"
    },
    {
        id: 4,
        title: "Payout",
        link: "payout"
    },
    {
        id: 5,
        title: "Request",
        link: "request"
    },
]



function SideMenu({ route, second }) {
    // console.log(second)
    const params = useMemo(() => new URLSearchParams(window.location.search), []);
    const logout = () => {
        localStorage.removeItem("access_token")
        localStorage.removeItem("refresh_token")
        window.location.href = "https://competition.gohamma.com/login"
    }

    return (
        <div className='w-[12%] h-[100vh] border-r-[0.3px] border-[#808080] pt-[2.12rem] hidden lg:block'>
            <Link to="/">
                <svg className='ml-[1.37rem]' width="70%" height="206" viewBox="0 0 940 206" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M865.04 63.1738H877.533V206H865.04V63.1738Z" fill="#161616" />
                    <path d="M802.573 6.86662C802.573 3.07429 805.649 0 809.444 0H933.129C936.924 0 940 3.0743 940 6.86663V71.4131C940 75.2054 936.924 78.2797 933.129 78.2797H809.444C805.649 78.2797 802.573 75.2054 802.573 71.4131V6.86662Z" fill="#19F256" />
                    <path d="M207.232 167.966H210.403V205.997H207.232V167.966Z" fill="#161616" />
                    <path d="M197.707 164.799H220.964V179.251H197.707V164.799Z" fill="#19F256" />
                    <path d="M75.0351 8.23995H119.149V205.999H75.0351V128.955H44.114V205.999H0V8.23995H44.114V85.2836H75.0351V8.23995Z" fill="#161616" />
                    <path d="M228.858 8.23995L285.752 205.999H239.989L206.594 90.6396L173.2 205.999H127.437L184.744 8.23995H228.858Z" fill="#161616" />
                    <path d="M403.99 8.23995H447.692V205.999H403.578V107.119L380.49 159.443H361.113L338.025 107.119V205.999H293.911V8.23995H338.025L371.008 82.3996L403.99 8.23995Z" fill="#161616" />
                    <path d="M574.297 8.23995H617.999V205.999H573.885V107.119L550.797 159.443H531.42L508.332 107.119V205.999H464.218V8.23995H508.332L541.315 82.3996L574.297 8.23995Z" fill="#161616" />
                    <path d="M727.701 8.23995L784.596 205.999H738.833L705.438 90.6396L672.043 205.999H626.28L683.587 8.23995H727.701Z" fill="#161616" />
                </svg>

                {/* <img src="https://res.cloudinary.com/ddlupbcws/image/upload/v1680791936/logo_2_ft9ja_1_fsh9iz.png" alt="" className='w-[5.75rem] ml-[1.37rem] mb-[4.06rem]' /> */}
            </Link>
            {params.get("trader_email") ? <div className='flex flex-col gap-[1.5rem] cursor-pointer'>
                {supportmenu.map(me => (
                    <Link key={me.id} to={`/?${me.link}&trader_email=${params.get("trader_email")}`} className={`${me.link === route ? "bg-[#EDFFED] text-[#00C500] border-r-[#008000] border-r-[3px]" : "text-[#808080]"} font-plus font-semibold py-[0.55rem]`}>
                        <p className='pl-[1.37rem]'>{me.title}</p>
                    </Link>
                ))}
                {/* <p onClick={logout} className='font-plus pl-[1.37rem] text-red-500 font-semibold'>Logout</p> */}
            </div> : <div className='flex flex-col gap-[1.5rem] cursor-pointer'>
                {menu.map(me => (
                    <Link key={me.id} to={`/?${me.link}`} className={`${me.link === route ? "bg-[#EDFFED] text-[#00C500] border-r-[#008000] border-r-[3px]" : "text-[#808080]"} font-plus font-semibold py-[0.55rem]`}>
                        <p className='xl:pl-[1.37rem] pl-[0.5rem]'>{me.title}</p>
                    </Link>
                ))}
                <p onClick={logout} className='font-plus pl-[1.37rem] text-red-500 font-semibold'>Logout</p>
            </div>}
        </div>
    )
}

export default SideMenu