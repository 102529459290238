import React from 'react'
import { Link } from 'react-router-dom'

function PaymentTable(props) {
    return (
        <table className="w-[100%] border-collapse bg-white rounded-[1.25rem] ">
            <thead className="rounded-[1.25rem]">
                <tr className="w-[100%]">
                    {props.column.map((row) => (
                        <td
                            className={`text-center whitespace-nowrap py-[1rem] font-plus ${row.key === "total-profit" ? "text-[#00C500]" : "text-black"} text-[0.875rem] font-semibold`}
                            key={row.key}
                        >
                            {row.label}
                        </td>
                    ))}
                </tr>
            </thead>
            <tbody className="w-[100%]">
              
                    <tr className="border-y border-[#80808080]">
                        <td className="lg:text-center whitespace-nowrap py-[1.5rem] lg:px-[0.5rem] px-[1rem] font-plus text-[0.875rem] font-medium text-black">
                            {props.data.bank}
                        </td>
                        <td className="lg:text-center whitespace-nowrap py-[1rem] lg:px-[0.5rem] px-[1rem] font-plus text-[0.875rem] font-medium text-black">
                            {props.data.acc_num}
                        </td>
                        {/* <td className="lg:text-center whitespace-nowrap py-[1rem] lg:px-[0.5rem] px-[1rem] font-plus text-[0.875rem] font-medium text-black">
                            {item.date}
                        </td> */}
                        <td className='flex justify-center gap-[0.62rem] pt-[1rem] pr-[0.5rem]'>
                            <Link to="/payout-history">
                                <button className='font-plus text-[0.875rem] border border-black font-semibold text-black bg-white py-[0.44rem] whitespace-nowrap px-[1.88rem] rounded-[0.3125rem]'>Payout history</button>
                            </Link>
                        </td>

                    </tr>
            
            </tbody>
        </table>
    )
}

export default PaymentTable