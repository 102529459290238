import React, { useState } from 'react'
import Select from 'react-select'
import PaymentTable from './tables/PaymentTable';
import axios from '../axios'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const headers = [
  {
    key: "bank",
    label: "Bank",
  },
  {
    key: "account_number",
    label: "Account Number",
  },
];



const countries = [
  { label: 'Afghanistan', value: 'Afghanistan' },
  { label: 'Albania', value: 'Albania' },
  { label: 'Algeria', value: 'Algeria' },
  { label: 'Andorra', value: 'Andorra' },
  { label: 'Angola', value: 'Angola' },
  { label: 'Anguilla', value: 'Anguilla' },
  { label: 'Antigua & Barbuda', value: 'Antigua & Barbuda' },
  { label: 'Argentina', value: 'Argentina' },
  { label: 'Armenia', value: 'Armenia' },
  { label: 'Aruba', value: 'Aruba' },
  { label: 'Australia', value: 'Australia' },
  { label: 'Austria', value: 'Austria' },
  { label: 'Azerbaijan', value: 'Azerbaijan' },
  { label: 'Bahamas', value: 'Bahamas' },
  { label: 'Bahrain', value: 'Bahrain' },
  { label: 'Bangladesh', value: 'Bangladesh' },
  { label: 'Barbados', value: 'Barbados' },
  { label: 'Belarus', value: 'Belarus' },
  { label: 'Belgium', value: 'Belgium' },
  { label: 'Belize', value: 'Belize' },
  { label: 'Benin', value: 'Benin' },
  { label: 'Bermuda', value: 'Bermuda' },
  { label: 'Bhutan', value: 'Bhutan' },
  { label: 'Bolivia', value: 'Bolivia' },
  { label: 'Bosnia & Herzegovina', value: 'Bosnia & Herzegovina' },
  { label: 'Botswana', value: 'Botswana' },
  { label: 'Brazil', value: 'Brazil' },
  { label: 'British Virgin Islands', value: 'British Virgin Islands' },
  { label: 'Brunei', value: 'Brunei' },
  { label: 'Bulgaria', value: 'Bulgaria' },
  { label: 'Burkina Faso', value: 'Burkina Faso' },
  { label: 'Burundi', value: 'Burundi' },
  { label: 'Cambodia', value: 'Cambodia' },
  { label: 'Cameroon', value: 'Cameroon' },
  { label: 'Cape Verde', value: 'Cape Verde' },
  { label: 'Cayman Islands', value: 'Cayman Islands' },
  { label: 'Chad', value: 'Chad' },
  { label: 'Chile', value: 'Chile' },
  { label: 'China', value: 'China' },
  { label: 'Colombia', value: 'Colombia' },
  { label: 'Congo', value: 'Congo' },
  { label: 'Cook Islands', value: 'Cook Islands' },
  { label: 'Costa Rica', value: 'Costa Rica' },
  { label: 'Cote D Ivoire', value: 'Cote D Ivoire' },
  { label: 'Croatia', value: 'Croatia' },
  { label: 'Cruise Ship', value: 'Cruise Ship' },
  { label: 'Cuba', value: 'Cuba' },
  { label: 'Cyprus', value: 'Cyprus' },
  { label: 'Czech Republic', value: 'Czech Republic' },
  { label: 'Denmark', value: 'Denmark' },
  { label: 'Djibouti', value: 'Djibouti' },
  { label: 'Dominica', value: 'Dominica' },
  { label: 'Dominican Republic', value: 'Dominican Republic' },
  { label: 'Ecuador', value: 'Ecuador' },
  { label: 'Egypt', value: 'Egypt' },
  { label: 'El Salvador', value: 'El Salvador' },
  { label: 'Equatorial Guinea', value: 'Equatorial Guinea' },
  { label: 'Estonia', value: 'Estonia' },
  { label: 'Ethiopia', value: 'Ethiopia' },
  { label: 'Falkland Islands', value: 'Falkland Islands' },
  { label: 'Faroe Islands', value: 'Faroe Islands' },
  { label: 'Fiji', value: 'Fiji' },
  { label: 'Finland', value: 'Finland' },
  { label: 'France', value: 'France' },
  { label: 'French Polynesia', value: 'French Polynesia' },
  { label: 'French West Indies', value: 'French West Indies' },
  { label: 'Gabon', value: 'Gabon' },
  { label: 'Gambia', value: 'Gambia' },
  { label: 'Georgia', value: 'Georgia' },
  { label: 'Germany', value: 'Germany' },
  { label: 'Ghana', value: 'Ghana' },
  { label: 'Gibraltar', value: 'Gibraltar' },
  { label: 'Greece', value: 'Greece' },
  { label: 'Greenland', value: 'Greenland' },
  { label: 'Grenada', value: 'Grenada' },
  { label: 'Guam', value: 'Guam' },
  { label: 'Guatemala', value: 'Guatemala' },
  { label: 'Guernsey', value: 'Guernsey' },
  { label: 'Guinea', value: 'Guinea' },
  { label: 'Guinea Bissau', value: 'Guinea Bissau' },
  { label: 'Guyana', value: 'Guyana' },
  { label: 'Haiti', value: 'Haiti' },
  { label: 'Honduras', value: 'Honduras' },
  { label: 'Hong Kong', value: 'Hong Kong' },
  { label: 'Hungary', value: 'Hungary' },
  { label: 'Iceland', value: 'Iceland' },
  { label: 'India', value: 'India' },
  { label: 'Indonesia', value: 'Indonesia' },
  { label: 'Iran', value: 'Iran' },
  { label: 'Iraq', value: 'Iraq' },
  { label: 'Ireland', value: 'Ireland' },
  { label: 'Isle of Man', value: 'Isle of Man' },
  { label: 'Israel', value: 'Israel' },
  { label: 'Italy', value: 'Italy' },
  { label: 'Jamaica', value: 'Jamaica' },
  { label: 'Japan', value: 'Japan' },
  { label: 'Jersey', value: 'Jersey' },
  { label: 'Jordan', value: 'Jordan' },
  { label: 'Kazakhstan', value: 'Kazakhstan' },
  { label: 'Kenya', value: 'Kenya' },
  { label: 'Kuwait', value: 'Kuwait' },
  { label: 'Kyrgyz Republic', value: 'Kyrgyz Republic' },
  { label: 'Laos', value: 'Laos' },
  { label: 'Latvia', value: 'Latvia' },
  { label: 'Lebanon', value: 'Lebanon' },
  { label: 'Lesotho', value: 'Lesotho' },
  { label: 'Liberia', value: 'Liberia' },
  { label: 'Libya', value: 'Libya' },
  { label: 'Liechtenstein', value: 'Liechtenstein' },
  { label: 'Lithuania', value: 'Lithuania' },
  { label: 'Luxembourg', value: 'Luxembourg' },
  { label: 'Macau', value: 'Macau' },
  { label: 'Macedonia', value: 'Macedonia' },
  { label: 'Madagascar', value: 'Madagascar' },
  { label: 'Malawi', value: 'Malawi' },
  { label: 'Malaysia', value: 'Malaysia' },
  { label: 'Maldives', value: 'Maldives' },
  { label: 'Mali', value: 'Mali' },
  { label: 'Malta', value: 'Malta' },
  { label: 'Mauritania', value: 'Mauritania' },
  { label: 'Mauritius', value: 'Mauritius' },
  { label: 'Mexico', value: 'Mexico' },
  { label: 'Moldova', value: 'Moldova' },
  { label: 'Monaco', value: 'Monaco' },
  { label: 'Mongolia', value: 'Mongolia' },
  { label: 'Montenegro', value: 'Montenegro' },
  { label: 'Montserrat', value: 'Montserrat' },
  { label: 'Morocco', value: 'Morocco' },
  { label: 'Mozambique', value: 'Mozambique' },
  { label: 'Namibia', value: 'Namibia' },
  { label: 'Nepal', value: 'Nepal' },
  { label: 'Netherlands', value: 'Netherlands' },
  { label: 'Netherlands Antilles', value: 'Netherlands Antilles' },
  { label: 'New Caledonia', value: 'New Caledonia' },
  { label: 'New Zealand', value: 'New Zealand' },
  { label: 'Nicaragua', value: 'Nicaragua' },
  { label: 'Niger', value: 'Niger' },
  { label: 'Nigeria', value: 'Nigeria' },
  { label: 'Norway', value: 'Norway' },
  { label: 'Oman', value: 'Oman' },
  { label: 'Pakistan', value: 'Pakistan' },
  { label: 'Palestine', value: 'Palestine' },
  { label: 'Panama', value: 'Panama' },
  { label: 'Papua New Guinea', value: 'Papua New Guinea' },
  { label: 'Paraguay', value: 'Paraguay' },
  { label: 'Peru', value: 'Peru' },
  { label: 'Philippines', value: 'Philippines' },
  { label: 'Poland', value: 'Poland' },
  { label: 'Portugal', value: 'Portugal' },
  { label: 'Puerto Rico', value: 'Puerto Rico' },
  { label: 'Qatar', value: 'Qatar' },
  { label: 'Reunion', value: 'Reunion' },
  { label: 'Romania', value: 'Romania' },
  { label: 'Russia', value: 'Russia' },
  { label: 'Rwanda', value: 'Rwanda' },
  { label: 'Saint Pierre & Miquelon', value: 'Saint Pierre & Miquelon' },
  { label: 'Samoa', value: 'Samoa' },
  { label: 'San Marino', value: 'San Marino' },
  { label: 'Satellite', value: 'Satellite' },
  { label: 'Saudi Arabia', value: 'Saudi Arabia' },
  { label: 'Senegal', value: 'Senegal' },
  { label: 'Serbia', value: 'Serbia' },
  { label: 'Seychelles', value: 'Seychelles' },
  { label: 'Sierra Leone', value: 'Sierra Leone' },
  { label: 'Singapore', value: 'Singapore' },
  { label: 'Slovakia', value: 'Slovakia' },
  { label: 'Slovenia', value: 'Slovenia' },
  { label: 'South Africa', value: 'South Africa' },
  { label: 'South Korea', value: 'South Korea' },
  { label: 'Spain', value: 'Spain' },
  { label: 'Sri Lanka', value: 'Sri Lanka' },
  { label: 'St Kitts & Nevis', value: 'St Kitts & Nevis' },
  { label: 'St Lucia', value: 'St Lucia' },
  { label: 'St Vincent', value: 'St Vincent' },
  { label: 'St. Lucia', value: 'St. Lucia' },
  { label: 'Sudan', value: 'Sudan' },
  { label: 'Suriname', value: 'Suriname' },
  { label: 'Swaziland', value: 'Swaziland' },
  { label: 'Sweden', value: 'Sweden' },
  { label: 'Switzerland', value: 'Switzerland' },
  { label: 'Syria', value: 'Syria' },
  { label: 'Taiwan', value: 'Taiwan' },
  { label: 'Tajikistan', value: 'Tajikistan' },
  { label: 'Tanzania', value: 'Tanzania' },
  { label: 'Thailand', value: 'Thailand' },
  { label: "Timor L'Este", value: "Timor L'Este" },
  { label: 'Togo', value: 'Togo' },
  { label: 'Tonga', value: 'Tonga' },
  { label: 'Trinidad & Tobago', value: 'Trinidad & Tobago' },
  { label: 'Tunisia', value: 'Tunisia' },
  { label: 'Turkey', value: 'Turkey' },
  { label: 'Turkmenistan', value: 'Turkmenistan' },
  { label: 'Turks & Caicos', value: 'Turks & Caicos' },
  { label: 'Uganda', value: 'Uganda' },
  { label: 'Ukraine', value: 'Ukraine' },
  { label: 'United Arab Emirates', value: 'United Arab Emirates' },
  { label: 'United Kingdom', value: 'United Kingdom' },
  { label: 'Uruguay', value: 'Uruguay' },
  { label: 'Uzbekistan', value: 'Uzbekistan' },
  { label: 'Venezuela', value: 'Venezuela' },
  { label: 'Vietnam', value: 'Vietnam' },
  { label: 'Virgin Islands (US)', value: 'Virgin Islands (US)' },
  { label: 'Yemen', value: 'Yemen' },
  { label: 'Zambia', value: 'Zambia' },
  { label: 'Zimbabwe', value: 'Zimbabwe' }
]




function Profile() {
  const [profPage, setProfPage] = useState(0)
  const [userData, setUserData] = useState({
    fName: "",
    lName: "",
    email: "",
    nation: ""
  })
  const [bank, setBank] = useState({
    bank: "",
    acc_num: "",
  })
  const [count, setCount] = useState({ value: "", label: "" })
  const [country, setCountry] = useState("")
  const [loading, setLoading] = useState(false)
  console.log(count)

  const showRequestSuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const showRequestFailed = (message) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  React.useEffect(() => {
    axios.get("auth/profile/").then((res) => {
      console.log(res.data)
      setUserData(prevState => {
        const updatedObject = { ...prevState };

        updatedObject.fName = res.data.user.firstname;
        updatedObject.lName = res.data.user.lastname;
        updatedObject.email = res.data.user.email;

        return updatedObject

      })
      setCount({ label: res.data.detail.country, value: res.data.detail.country })
      // setAcc(res.data.filter(acc => acc.acc.number !== 0));
    });
    axios.get("auth/profile/").then((res) => {
      console.log(res.data);
      if (res.data.account_number !== null && res.data.bank_name !== null) {
        setBank({ bank: res.data.bank_name, acc_num: res.data.account_number })


      } else {
        setBank({
          bank: "",
          acc_num: "",
        })
      }

    }).catch(err => {
      console.log(err)
    });

  }, []);

  const UpdatePersonalInformation = () => {
    setLoading(true);
    axios
      .put("auth/profile/", {
        country: country,
      })
      .then((res) => {
        setLoading(false);
        console.log(res.data);
        showRequestSuccess("Successfully updated nationality")
      }).catch(err => {
        console.log(err)
        showRequestFailed("Failed to update nationality")
      })
  };

  return (
    <div className='px-[1rem] '>
      <ToastContainer />
      <div className='border-b border-[#80808080] flex items-center lg:pl-[4.69rem] gap-[4rem]'>
        <p onClick={() => setProfPage(0)} className={`font-plus font-bold text-[1rem] text-[#151515] cursor-pointer pb-[1.25rem] pt-[3rem] ${profPage === 0 ? "border-b-[3px] border-b-[#00C500]" : "border-b-[3px] border-b-[#F4F4F4]"}`}>General</p>
        {/* <p onClick={() => setProfPage(1)} className={`font-plus font-bold text-[1rem] text-[#151515] cursor-pointer pb-[1.25rem] pt-[3rem] ${profPage === 1 ? "border-b-[3px] border-b-[#00C500]" : "border-b-[3px] border-b-[#F4F4F4]"}`}>Payment</p> */}
      </div>

      {profPage === 0 && <div>
        <div className='lg:pl-[4.69rem] mt-[3.12rem]'>
          <h1 className='font-plus text-[1.25rem] font-bold text-[#151515] mb-[2.5rem]'>PERSONAL DETAILS</h1>
          <form action="">
            <div className='flex flex-col lg:flex-row  lg:items-center lg:gap-[8.44rem] gap-[2rem]'>
              <div className='flex flex-col gap-[1.25rem]'>
                <label htmlFor="" className='font-plus text-[1rem] font-medium text-[#151515]'>First name</label>
                <input value={userData?.fName} type="text" className='bg-[#F4F4F4] border-b border-[#80808080] py-[0.62rem] font-plus placeholder:font-plus lg:w-[25.25rem] outline-none' placeholder='First name' />
              </div>
              <div className='flex flex-col gap-[1.25rem]'>
                <label htmlFor="" className='font-plus text-[1rem] font-medium text-[#151515]'>Last name</label>
                <input value={userData?.lName} type="text" className='bg-[#F4F4F4] border-b border-[#80808080] py-[0.62rem] font-plus placeholder:font-plus lg:w-[25.25rem] outline-none' placeholder='Last name' />
              </div>
            </div>
            <div className='flex flex-col lg:flex-row lg:items-center lg:gap-[8.44rem] gap-[2rem] mt-[2.12rem]'>
              <div className='flex flex-col gap-[1.25rem]'>
                <label htmlFor="" className='font-plus text-[1rem] font-medium text-[#151515]'>Email address</label>
                <input value={userData?.email} type="text" className='bg-[#F4F4F4] border-b border-[#80808080] py-[0.62rem] font-plus placeholder:font-plus lg:w-[25.25rem] outline-none' placeholder='Email' />
              </div>
              <div className='flex flex-col gap-[1.25rem] lg:w-[25.25rem]'>
                <label htmlFor="" className='font-plus text-[1rem] font-medium text-[#151515]'>Nationality</label>
                <Select value={count} placeholder="Select country" options={countries} onChange={(e) => {
                  setCount({ value: e.value, label: e.value })
                  setCountry(e.value)
                }
                } />
              </div>
            </div>
            <button disabled={loading} onClick={UpdatePersonalInformation} className='font-plus bg-[#00C500] text-white font-medium text-[1rem] rounded-[0.3125rem] py-[1.25rem] px-[1.88rem] mt-[2.75rem]'>{loading ? "Please wait..." : "Update Nationality"}</button>
          </form>
          <h1 className='font-plus text-[1.25rem] font-bold text-[#151515] mt-[5rem]'>Security</h1>
          <button className='font-plus bg-[#00C500] text-white font-medium text-[1rem] rounded-[0.3125rem] py-[1.25rem] px-[1.88rem] mt-[2.75rem] mb-[2.5rem]'>Reset Password</button>

        </div>
      </div>}

      {profPage === 1 && <div className='lg:pl-[4.69rem] mt-[3.12rem]'>
        <h1 className='font-plus text-[1.25rem] font-bold text-[#151515] mt-[5rem] mb-[2.5rem]'>Payment</h1>
        <div className='bg-white pb-10 lg:w-[56.25rem] rounded-[0.3125rem] border border-[#80808080] w-[100%] overflow-x-scroll no-scrollbar '>
          <PaymentTable column={headers} data={bank} />
        </div>
      </div>}
    </div>
  )
}

export default Profile