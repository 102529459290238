import React from 'react'
import { AccContext } from '../../context/OtherContext';
function toPascalCaseWithSpaces(inputString) {
  // Split the string into words
  const words = inputString.split(' ');

  // Capitalize the first letter of each word and convert the rest to lowercase
  const pascalCaseWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

  // Join the words back together with spaces
  const pascalCaseString = pascalCaseWords.join(' ');

  return pascalCaseString;
}

function LeadershipBoardTable(props) {
  const { values4 } = React.useContext(AccContext)
  console.log(props.data)

  const [countries] = values4
  console.log(countries)


  return (
    <div className='bg-white w-[100%]'>
      <div className='w-[100%] overflow-scroll lg:max-h-[760px] max-h-[790px] no-scrollbar'>
        {props.data.length < 1 && <div>
          <div className='flex justify-between mb-10'>
            {props.column.map((row) => (
              <p
                className={`text-center py-[1rem] px-[1rem] whitespace-nowrap font-plus ${row.key === "total-profit" ? "text-[#00C500]" : row.key === "clan" ? "text-[#FFA500]" : "text-[#151515]"} text-[0.875rem] font-semibold`}
                key={row.key}
              >
                {row.label}
              </p>
            ))}
          </div>
          <div><img src='https://res.cloudinary.com/ddlupbcws/image/upload/q_100/v1712756120/E-library_cby0bj.webp' alt='' className='w-[114px] h-[114px] m-auto' />
            <p className='text-center font-plus text-[#161616] font-medium text-[14px] mt-[24px]'>Nothing to see here yet</p>
            <p className='text-center font-plus text-[#16161699] font-medium text-[12px] my-[5px]'>Start trading to appear on the leaderboard</p>
          </div>
        </div>}
        {props.data.length > 0 && <table className="w-[100%] border-collapse">
          <thead>
            <tr className="w-[100%]">
              {props.column.map((row) => (
                <td
                  className={`text-center py-[1rem] px-[1rem] whitespace-nowrap font-plus ${row.key === "clan" ? "text-[#FFA500]" : "text-[#151515]"} text-[0.875rem] font-semibold`}
                  key={row.key}
                >
                  <div className='flex items-center justify-center'>
                    {row.label} {row.key === "total-profit-naira" && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 18V7.052a1.05 1.05 0 0 1 1.968-.51l6.064 10.916a1.05 1.05 0 0 0 1.968-.51V6M5 10h14M5 14h14" /></svg>}
                  </div>
                </td>
              ))}
            </tr>
          </thead>
          <tbody className="w-[100%]">
            {props.data.map((item, index) => (
              <tr key={index} className="border-y border-gray-200">
                <td className="text-center py-[1rem] px-[0.5rem] pl-[0.3rem] lg:pl-[0.5rem] font-plus text-[0.875rem] font-medium text-[#151515]">
                  {index + 1}.
                </td>
                <td className="text-center whitespace-nowrap py-[1rem] lg:px-[0.5rem] font-plus text-[0.875rem] font-medium text-[#151515]">
                  {toPascalCaseWithSpaces(item.name)}
                </td>
                {/* <td className="text-center py-[1rem] lg:px-[0.5rem] font-plus text-[0.875rem] font-medium text-[#151515]">
                  {item.acc_size}
                </td>
                <td className="text-center py-[1rem] lg:px-[0.5rem] font-plus text-[0.875rem] font-medium text-[#151515]">
                  {item.typeofaccount}
                </td> */}
                <td className={`text-center py-[1rem] px-[0.5rem] font-plus text-[0.875rem] font-medium ${item.profit < 0 ? "text-red-500" : "text-[#00C500]"}`}>
                  {item.profit}%
                </td>
                <td className={`text-center py-[1rem] px-[0.5rem] font-plus text-[0.875rem] font-medium ${item.profit < 0 ? "text-red-500" : "text-[#00C500]"}`}>
                  <div className='flex items-center justify-center'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="none" stroke={`${item.profit_naira < 0 ? "#ef4444" : "#00C500"}`} stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 18V7.052a1.05 1.05 0 0 1 1.968-.51l6.064 10.916a1.05 1.05 0 0 0 1.968-.51V6M5 10h14M5 14h14" /></svg>
                    {item.profit_naira}
                  </div>
                </td>
                <td className="text-center whitespace-nowrap py-[1rem] px-[0.5rem] font-plus text-[0.875rem] font-medium text-[#FFA500]">
                  {item.clan}
                </td>
                <td className="flex justify-center py-[1rem] px-[0.5rem] font-plus text-[0.875rem] font-medium text-white">
                  {countries.filter(country => country.countryName.toLowerCase() === item.country.toLowerCase()).map(count => <img key={count.countryName} className='w-[1.75rem] h-[1.75rem] rounded-full' src={count.flagImageUrl} alt="" />)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>}
      </div>
    </div>
  )
}

export default LeadershipBoardTable