import Notification from "../assets/notification.svg"
import User from "../assets/user-search.svg"
import NotifModal from './modals/NotifModal';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react'
import axios from '../axios'
import { AccContext } from "../context/OtherContext";
import MobileMenu from "./modals/MobileMenu";

function toPascalCase(inputString) {
    if(inputString){

        // Split the string by spaces or underscores
        const words = inputString.split(/[\s_]+/);
        
        // Capitalize the first letter of each word
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].slice(1);
        }
        
        // Join the words back together
        return words.join('');
    }else{
        return "Home"
    }
}


function TopMenu({ route }) {
    let navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const [mobileOpen, setMobileOpen] = useState(false)
    const [available, setAvailable] = useState(false)
    const { values2 } = React.useContext(AccContext)
    // eslint-disable-next-line no-unused-vars
    const [globalRefresh, setGlobalRefresh] = values2
    const params = useMemo(() => new URLSearchParams(window.location.search), []);


    useEffect(() => {
        const intervalId = setInterval(axios.get("leaderboard/notifications/").then((res) => {
            console.log(res);
            if (res.data.length > 0) {
                setAvailable(true);
            } else {
                setAvailable(false);
            }
        }).catch(err => {
            console.log(err);
        }), 120000);

        // Clear the interval when the component unmounts to prevent memory leaks
        return () => {
            clearInterval(intervalId);
        };
    }, [globalRefresh])

    return (
        <div className='lg:sticky top-0  z-50 lg:border-b-[0.3px] lg:border-b-[#808080] lg:bg-white flex items-center justify-between lg:pl-[4.69rem] lg:pr-[2.25rem] pl-[0.94rem] pr-[1.29rem] py-[1.31rem]'>
            {route === "payout-history" ? <button onClick={() => navigate(-1)} className='py-[0.69rem] hidden lg:block lg:px-[1.44rem] px-[0.7rem] border border-[#0080004D]'>{"<<"} Back</button> : <h1 className='font-plus text-[1.25rem] font-bold text-[#151515] hidden lg:block'>{toPascalCase(route)}</h1>}
            {/* <img src="https://res.cloudinary.com/ddlupbcws/image/upload/v1680791936/logo_2_ft9ja_1_fsh9iz.png" alt="" className='w-[5.75rem]  lg:hidden' /> */}
            <svg onClick={() => setMobileOpen(!mobileOpen)} xmlns="http://www.w3.org/2000/svg" width="29" height="17" viewBox="0 0 29 17" fill="none" className="lg:hidden">
                <rect width="29" height="3.05263" rx="1.52632" fill="#151515" />
                <rect y="6.86841" width="29" height="3.05263" rx="1.52632" fill="#151515" />
                <rect y="13.7368" width="29" height="3.05263" rx="1.52632" fill="#151515" />
            </svg>
            {!params.get("trader_email") && <div className='flex items-center lg:gap-[2.5rem] gap-[1.88rem]'>
                <Link to="/?profile" >
                    <div className='w-[3.125rem] h-[2.74788rem] flex items-center justify-center shadow-[0px_0px_6px_0px_rgba(0,0,0,0.1)] bg-white rounded-[0.3125rem] lg:rounded-none'>
                        <img src={User} alt='' />
                    </div>
                </Link>
                <div onClick={() => setOpen(!open)} className='relative cursor-pointer w-[3.125rem] h-[2.74788rem] flex items-center justify-center shadow-[0px_0px_6px_0px_rgba(0,0,0,0.1)] bg-white rounded-[0.3125rem] lg:rounded-none'>
                    {available && <div className="w-[10px] h-[10px] rounded-full absolute top-1 right-1 bg-red-500"></div>}
                    <img src={Notification} alt='' />
                </div>
            </div>}
            <NotifModal isOpen={open} setIsOpen={setOpen} />
            <MobileMenu isOpen={mobileOpen} setIsOpen={setMobileOpen} />
        </div>
    )
}

export default TopMenu