import { Dialog } from '@headlessui/react'
import React, { useRef } from 'react'


function LoadingClans(props) {
    let completeButtonRef = useRef(null)
    return (

        <Dialog
            open={props.isOpen}
            initialFocus={completeButtonRef}
            onClose={() => props.setIsOpen(false)}
            className="relative z-50"
            as="div"
        >
            {/* The backdrop, rendered as a fixed sibling to the panel container */}
            <div className="fixed inset-0 bg-[#15151566]" aria-hidden="true" />

            {/* Full-screen container to center the panel */}
            <div className="fixed inset-0 flex items-center justify-center">
                {/* The actual dialog panel  */}

                <Dialog.Panel className="mx-auto lg:w-[25.75rem] w-[95%] pb-10 overflow-y-scroll no-scrollbar h-[15.3125rem] bg-white rounded-[1.25rem]">
                    <div className="h-[100%] flex flex-col items-center justify-center">

                        <p
                            ref={completeButtonRef}
                            onClick={() => props.setIsOpen(false)}
                            className="hidden font-poppins text-[#BE8688] text-[1.25rem] font-medium"
                        >
                            close
                        </p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 24 24"><g fill="none" stroke="#02915d" stroke-linecap="round" stroke-width="2"><path stroke-dasharray="60" stroke-dashoffset="60" stroke-opacity=".3" d="M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3Z"><animate fill="freeze" attributeName="stroke-dashoffset" dur="1.3s" values="60;0" /></path><path stroke-dasharray="15" stroke-dashoffset="15" d="M12 3C16.9706 3 21 7.02944 21 12"><animate fill="freeze" attributeName="stroke-dashoffset" dur="0.3s" values="15;0" /><animateTransform attributeName="transform" dur="1.5s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12" /></path></g></svg>
                        <p className='font-bold font-poppins text-[1.5rem]'>Loading clans...</p>




                    </div>
                </Dialog.Panel>

            </div>
        </Dialog>

    )
}

export default LoadingClans