import React from 'react'

function ConvertDate(params) {
    const date = new Date(params);

    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    };

    const formattedDate = date.toLocaleString("en-US", options);
    return formattedDate

}


function PayoutHistoryTable(props) {
    return (
        <table className="w-[100%] border-collapse bg-white rounded-[1.25rem] ">
            <thead className="rounded-[1.25rem]">
                <tr className="w-[100%]">
                    {props.column.map((row) => (
                        <td
                            className={`text-center py-[1rem] font-plus ${row.key === "total-profit" ? "text-[#00C500]" : "text-black"} text-[0.875rem] font-medium`}
                            key={row.key}
                        >
                            {row.label}
                        </td>
                    ))}
                </tr>
            </thead>
            <tbody className="w-[100%]">
                {props.data.map((item, index) => (
                    <tr key={item.id} className="border-y border-[#80808080]">
                        <td className="lg:text-center whitespace-nowrap py-[1.5rem] lg:px-[0.5rem] px-[1rem] font-plus text-[0.875rem] font-normal text-black">
                            {item.id}
                        </td>
                        <td className="lg:text-center whitespace-nowrap py-[1rem] lg:px-[0.5rem] px-[1rem] font-plus text-[0.875rem] font-normal text-black">
                            {item.amount}
                        </td>
                        <td className={`lg:text-center whitespace-nowrap py-[1rem] lg:px-[0.5rem] px-[1rem] font-plus text-[0.875rem] font-medium ${item.status.toLowerCase() === "successful" ? "text-green-500" : item.status.toLowerCase() === "pending" ? "text-yellow-500" : "text-red-500"}`}>
                            <p className={`py-[0.5rem] rounded-[0.3125rem] px-[0.5rem] text-center lg:w-[50%] mx-auto ${item.status.toLowerCase() === "successful" ? "bg-green-100" : item.status.toLowerCase() === "pending" ? "bg-yellow-100" : "bg-red-100"}`}>
                                {item.status}
                            </p>
                        </td>
                        <td className="lg:text-center whitespace-nowrap py-[1rem] lg:px-[0.5rem] px-[1rem] font-plus text-[0.875rem] font-normal text-black">
                            {ConvertDate(item.date)}
                        </td>
                        <td className="lg:text-center whitespace-nowrap py-[1rem] lg:px-[0.5rem] px-[1rem] font-plus text-[0.875rem] font-normal text-black">
                            {item.paytype}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default PayoutHistoryTable