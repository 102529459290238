import React from "react";
// import { useNavigate, Link } from "react-router-dom";
// import axios from "../axios";

export const AccContext = React.createContext();

export const AccProvider = (props) => {
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [compEndDate, setCompEndDate] = React.useState("");
  const [compStartDate, setCompStartDate] = React.useState("");
  const [globalRefresh, setGlobalRefresh] = React.useState(false);
  const [countries, setCountries] = React.useState([])

  return (
    <AccContext.Provider
      value={{
        values: [isAdmin, setIsAdmin],
        values1: [compEndDate, setCompEndDate],
        values2: [globalRefresh, setGlobalRefresh],
        values3: [compStartDate, setCompStartDate],
        values4: [countries, setCountries]
      }}
    >
      {props.children}
    </AccContext.Provider>
  );
};
