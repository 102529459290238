/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import SideMenu from '../components/SideMenu'
import TopMenu from '../components/TopMenu'
import { useLocation, useNavigate } from 'react-router-dom'
import MobileMenu from '../components/modals/MobileMenu'
import axios from '../axios'
import ClanTable from '../components/tables/ClanTable'

const headers = [
    {
        key: "id",
        label: "",
    },
    {
        key: "name",
        label: "Name",
    },
    // {
    //     key: "acc_size",
    //     label: "Account Size",
    // },
    // {
    //     key: "model",
    //     label: "Model",
    // },
    {
        key: "total-profit",
        label: "Total Profit",
    },
    {
        key: "country",
        label: "Country",
    },

];

// function toPascalCase(inputString) {
//     // Split the string by spaces or underscores
//     const words = inputString.split(/[\s_]+/);

//     // Capitalize the first letter of each word
//     for (let i = 0; i < words.length; i++) {
//         words[i] = words[i][0].toUpperCase() + words[i].slice(1);
//     }

//     // Join the words back together
//     return words.join('');
// }

function ClanDetails() {
    const [open, setOpen] = useState(false)
    const params = useLocation()
    const link = params.pathname.split("/")[1]
    const id = params.pathname.split("/")[2]
    const [clanDetails, setClanDetails] = useState({})
    const [data, setData] = useState([])
    const [refresh, setRefresh] = useState(false)
    const navigate = useNavigate()
    const [admin, setAdmin] = useState(false)
    console.log(id)

    const showRequestSuccess = (message) => {
        toast.success(message, {
            position: toast.POSITION.BOTTOM_CENTER,
        });
    };

    const showRequestFailed = (message) => {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_CENTER,
            theme: "dark",
        });
    };

    useEffect(() => {
        axios.get(`leaderboard/clans/${id}/`).then(res => {
            console.log(res.data)
            setClanDetails(res.data)
        }).catch(err => {
            console.log(err)
        })

        axios.get(`leaderboard/clan_members/${id}/`).then(res => {
            console.log(res.data)
            setAdmin(res.data[0].admin)
            setData(res.data[1].members)

        }).catch(err => {
            console.log(err)
        })
        // setAcc(res.data.filter(acc => acc.acc.number !== 0));
    }, [id])

    return (
        <div className='lg:flex'>
            <SideMenu route={link} />
            <div className='lg:w-[88%] bg-[#F4F4F4] lg:overflow-y-scroll lg:h-[100vh] lg:relative'>
                <TopMenu route={link} />
                <div className='lg:px-[5rem] px-[1rem] pb-[3.56rem]'>
                    <div className='bg-white relative flex flex-col items-center gap-[1.25rem] py-[1.61rem] mt-[1.88rem] rounded-[1.25rem] border border-[#80808080]'>
                        <button onClick={() => navigate(-1)} className='absolute left-0 top-0 font-plus text-[0.875rem] font-semibold text-[#151515] lg:px-[1.44rem] px-[0.2rem] lg:py-[0.69rem] py-[0.2rem] border-b border-r border-b-[#20CC20] border-r-[#20CC20]'>
                            {"<<"} Back
                        </button>
                        <img src={clanDetails.clan_logo} className='w-[12.72825rem] h-[7rem] object-contain' alt="" />
                        <p className='font-plus text-[1.5rem] font-extrabold text-[#151515]'>{clanDetails.clan_name}</p>
                        <p className='font-plus text-[0.875rem] font-normal text-center text-[#000000]'>{clanDetails.description}</p>
                        <a rel='noreferrer' target='_blank' href={clanDetails.community_link} className='font-plus text-[0.875rem] underline text-[#20CC20]'>Community link</a>
                    </div>
                    <div className='mt-[1.87rem] flex flex-col lg:flex-row lg:gap-[2.5rem] gap-[1.5rem]'>
                        <div className='bg-white rounded-[1.25rem] w-[20rem] h-[10rem] pt-[1.88rem] pl-[1.88rem] border border-[#80808080]'>
                            <p className='font-plus text-[1rem] font-medium text-[#151515] mb-[1.88rem]'>Members</p>
                            <p className='font-plus text-[2.25rem] font-extrabold text-[#151515]'>{clanDetails.members_count}</p>
                        </div>
                        <div className='bg-white rounded-[1.25rem] w-[20rem] h-[10rem] pt-[1rem] px-[1rem] border border-[#80808080]'>
                            <p className='font-plus text-[1rem] font-medium mb-[2.88rem] text-[#151515]'>Average return</p>
                            <div className='flex items-end justify-between'>

                                {clanDetails.profit_comparison && <p className='font-plus text-[2.25rem] font-extrabold text-[#151515]'>{clanDetails.profit_comparison[0]}%</p>}
                                {clanDetails.profit_comparison && clanDetails.profit_comparison[0] > 0 ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M18.6801 13.9802L15.4701 10.7702L13.5101 8.80023C12.6801 7.97023 11.3301 7.97023 10.5001 8.80023L5.32007 13.9802C4.64007 14.6602 5.13007 15.8202 6.08007 15.8202H11.6901H17.9201C18.8801 15.8202 19.3601 14.6602 18.6801 13.9802Z" fill="#00C500" />
                                </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M5.31993 10.0198L8.52993 13.2298L10.4899 15.1998C11.3199 16.0298 12.6699 16.0298 13.4999 15.1998L18.6799 10.0198C19.3599 9.33977 18.8699 8.17977 17.9199 8.17977H12.3099H6.07993C5.11993 8.17977 4.63993 9.33977 5.31993 10.0198Z" fill="#FF0000" />
                                </svg>}
                            </div>
                        </div>
                        <div className='bg-white rounded-[1.25rem] w-[20rem] h-[10rem] pt-[1rem] px-[1rem] border border-[#80808080]'>
                            <div className='flex items-center justify-between w-[100%]'>
                                <p className='font-plus text-[1rem] font-medium mb-[2.88rem] text-[#151515]'>No of times in top 3</p>
                                <p className='font-plus text-[0.75rem] font-medium mb-[2.88rem] text-[#151515]'>Per competiton</p>
                            </div>
                            <div className='flex items-end justify-between'>

                                <p className='font-plus text-[2.25rem] font-extrabold text-[#151515]'>{clanDetails.top_3_count}</p>
                            </div>
                        </div>

                    </div>
                    <div className='lg:w-[72.75rem] w-[100%] max-h-[56.5rem] bg-white mt-[2.44rem] rounded-[1.25rem]'>
                        <ClanTable showRequestFailed={showRequestFailed} showRequestSuccess={showRequestSuccess} refresh={refresh} setRefresh={setRefresh} clanId={id} column={headers} data={data} isAdmin={admin} />
                    </div>
                </div>
            </div>
            <MobileMenu isOpen={open} setIsOpen={setOpen} />
        </div>
    )
}

export default ClanDetails