import React, { useMemo, useState } from 'react'
import PayoutHistoryTable from './tables/PayoutHistoryTable';
import axios from '../axios';
import { Link } from 'react-router-dom';

const headers = [
  {
    key: "id",
    label: "ID",
  },
  {
    key: "amount",
    label: "Amount",
  },
  {
    key: "status",
    label: "Status",
  },
  {
    key: "date",
    label: "Date",
  },
  {
    key: "type",
    label: "Type",
  },
];


function PaymentHistory() {
  const [history, setHistory] = useState([])
  const params = useMemo(() => new URLSearchParams(window.location.search), []);
  React.useEffect(() => {
    if(params.get("trader_email")){
      axios.get(`leaderboard/trader-payout-history/support/${params.get("trader_email")}`).then((res) => {
        console.log(res)
        if (res.data.length > 0) {
          setHistory(res.data)
        }
  
      }).catch(err => {
        console.log(err)
      });
    }else{
      axios.get("leaderboard/trader-payout-history/").then((res) => {
        console.log(res)
        if (res.data.length > 0) {
          setHistory(res.data)
        }
  
      }).catch(err => {
        console.log(err)
      });
    }
  

  }, [params]);
  return (
    <div className='lg:px-[5rem] flex flex-col px-[1rem] pb-10 h-[100vh]'>
      <div className='mx-auto mt-[5rem] flex'>
        {params.get("trader_email") ? <Link to={`/?payout&trader_email=${params.get("trader_email")}`}>
          <button className='font-plus text-[#151515] font-bold bg-white px-[2.94rem] py-[0.88rem] border border-[#80808080] rounded-[0.625rem]'>Payout</button>
        </Link> : <Link to="/?payout">
          <button className='font-plus text-[#151515] font-bold bg-white px-[2.94rem] py-[0.88rem] border border-[#80808080] rounded-[0.625rem]'>Payout</button>
        </Link>}

        <button className='font-plus text-white whitespace-nowrap font-bold bg-[#00C500] px-[1.12rem] py-[0.88rem] border border-[#00C500] rounded-[0.625rem] ml-[0.62rem]'>Payout history</button>
      </div>
      <div className='mt-[5rem] mb-[2.5rem] flex items-center justify-between'>
        <h1 className='font-plus text-[1.25rem] font-bold text-[#151515]'>Payment</h1>
        <button className='flex items-center justify-center gap-[0.62rem] bg-white w-[8.75rem] h-[3.125rem] rounded-[0.3125rem] border border-[#0080004D]'>
          <p className='font-plus text-[#00C500] font-semibold text-[0.875rem]'>Statement</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19ZM12.28 15.78L10.28 17.78C10.21 17.85 10.12 17.91 10.03 17.94C9.94 17.98 9.85 18 9.75 18C9.65 18 9.56 17.98 9.47 17.94C9.39 17.91 9.31 17.85 9.25 17.79C9.24 17.78 9.23 17.78 9.23 17.77L7.23 15.77C6.94 15.48 6.94 15 7.23 14.71C7.52 14.42 8 14.42 8.29 14.71L9 15.44V11.25C9 10.84 9.34 10.5 9.75 10.5C10.16 10.5 10.5 10.84 10.5 11.25V15.44L11.22 14.72C11.51 14.43 11.99 14.43 12.28 14.72C12.57 15.01 12.57 15.49 12.28 15.78Z" fill="#00C500" />
            <path d="M17.4297 8.81048C18.3797 8.82048 19.6997 8.82048 20.8297 8.82048C21.3997 8.82048 21.6997 8.15048 21.2997 7.75048C19.8597 6.30048 17.2797 3.69048 15.7997 2.21048C15.3897 1.80048 14.6797 2.08048 14.6797 2.65048V6.14048C14.6797 7.60048 15.9197 8.81048 17.4297 8.81048Z" fill="#00C500" />
          </svg>
        </button>
      </div>

      <div className='pb-10 rounded-[1.25rem] bg-white w-[100%] mx-auto overflow-x-scroll no-scrollbar border border-[#80808080]'>
        <PayoutHistoryTable column={headers} data={history} />
      </div>
    </div>
  )
}

export default PaymentHistory