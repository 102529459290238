import { Dialog } from '@headlessui/react'
import React, { useRef, useState } from 'react'
import axios from '../../axios'
import { AccContext } from '../../context/OtherContext'

function Memebers(props) {
    let completeButtonRef = useRef(null)
    const [loading, setLoading] = useState(false)
    const { values2 } = React.useContext(AccContext)
    const [globalRefresh, setGlobalRefresh] = values2
    // console.log(props.accounts)

    const transfer = async (number) => {
        setLoading(true)
        // console.log(number)
        // console.log(localStorage.getItem("comp_id"))
        axios.put(`leaderboard/transfer-admin/${props.clanId}/${number}/`).then(res => {
                console.log(res)
                setLoading(false)
                props.setRefresh(!props.refresh)
                setGlobalRefresh(!globalRefresh)
                props.showRequestSuccess("Successfully transferred admin status")
                props.setIsOpen(!props.isOpen)
            }).catch(err => {
                console.log(err)
                setLoading(false)
                props.showRequestFailed("Failed to transfer admin status")
            })
       
    }

    return (

        <Dialog
            open={props.isOpen}
            initialFocus={completeButtonRef}
            onClose={() => props.setIsOpen(false)}
            className="relative z-50"
            as="div"
        >
            {/* The backdrop, rendered as a fixed sibling to the panel container */}
            <div className="fixed inset-0 bg-[#15151566]" aria-hidden="true" />

            {/* Full-screen container to center the panel */}
            <div className="fixed inset-0 flex items-center justify-center">
                {/* The actual dialog panel  */}

                <Dialog.Panel className="mx-auto lg:w-[50.75rem] w-[95%] lg:h-[27.5rem] h-[25rem] overflow-y-scroll bg-white rounded-[1.25rem]">
                    <div className=" p-[1rem]">
                        {props.data.length > 0 && <h1 className='text-center font-plus text-[1.5rem] font-bold mb-[1rem]'>Choose member to transfer admin rights to</h1>}
                        {props.data.length > 0 ?
                            <div className='flex flex-wrap gap-[1rem]'>

                                {props.data.map(dat => (
                                    <div key={dat.member_id} className='border border-[#20CC20] p-[1rem] flex flex-col'>
                                        <p className='font-plus text-[#151515] text-[1rem] font-medium'>Name: <span className='text-[#20CC20]'>{dat.name}</span></p>
                                        {/* <p className='font-plus text-[#151515] text-[1rem] font-medium'>Account Size: <span className='text-[#20CC20]'>{dat.acc_size}</span></p>
                                        <p className='font-plus text-[#151515] text-[1rem] font-medium'>Profit: <span className={`${dat.profit < 0 ? "text-red-500" : "text-[#00C500]"}`}>{dat.profit}</span></p> */}
                                        <button disabled={loading} onClick={() => transfer(dat.member_id)} className='bg-[#20CC20] disabled:bg-gray-500 text-white font-plus font-medium rounded-lg py-[0.2rem] mt-[1rem]'>Transfer</button>
                                    </div>
                                ))}
                            </div>
                            : <p className='font-plus text-center lg:mt-[8rem] mt-[5rem] text-gray-400 font-semibold text-[2rem]'>To transfer clan ownership, you need to have at least one member in your clan.</p>}

                        <p
                            ref={completeButtonRef}
                            onClick={() => props.setIsOpen(false)}
                            className="hidden font-poppins text-[#BE8688] text-[1.25rem] font-medium"
                        >
                        </p>



                    </div>
                </Dialog.Panel>

            </div>
        </Dialog>

    )
}

export default Memebers