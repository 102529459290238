import React, { useMemo } from "react";
import CalendarHeatmap from "react-calendar-heatmap";
import "react-calendar-heatmap/dist/styles.css";
import "../GitGraphs.css";
// import { Grid, Box } from "@mui/material";
import axios from "../axios";
import Carousel from "react-material-ui-carousel";
function TradingDays() {
    const [info, setInfo] = React.useState([]);
    const calendarStartDate = new Date("2024-05-01");
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [val, setVal] = React.useState("");
    const params = useMemo(() => new URLSearchParams(window.location.search), []);

    const calendarDatesArray = [];
    for (let i = 1; i < 13; i++) {
        calendarDatesArray.push({
            start: new Date(
                Date.UTC(
                    calendarStartDate.getFullYear(),
                    calendarStartDate.getMonth() + i - 1,
                    0
                )
            ),
            end: new Date(
                Date.UTC(
                    calendarStartDate.getFullYear(),
                    calendarStartDate.getMonth() + i,
                    0
                )
            ),
        });
    }
    React.useEffect(() => {
        if (params.get("trader_email")) {
            axios
                .get(`/leaderboard/trading-days/support/${params.get("trader_email")}`)
                .then((res) => {
                    console.log(res.data);
                    setInfo(
                        res.data.trading_days.map((item) => {
                            return { date: item };
                        })
                    );
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {

            axios
                .get("/leaderboard/trading-days/")
                .then((res) => {
                    console.log(res.data);
                    setInfo(
                        res.data.trading_days.map((item) => {
                            return { date: item };
                        })
                    );
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [params]);

    let handleClick = (value) => {
        if (value === null) {
            setOpen2(true);
            setVal("no trading record for this day");
        } else {
            setOpen(true);
            setVal(value);
            //console.log(value);
        }
    };

    return (
        <Carousel
            autoPlay={false}
            animation="slide"
            indicators={true}
            navButtonsProps={{
                style: {
                    backgroundColor: "#359602",
                },
            }}
        >
            {calendarDatesArray.map((date, index) => (
                // <Grid item xs={9} key={index}>
                <div style={{ margin: "6% 25% 0% 25%" }}>
                    <dialog
                        onClick={() => {
                            setOpen(false);
                        }}
                        open={open}
                        style={{padding:"10px", border:"1px solid black"}}
                    >
                        <div>
                            Traded on this day {val.date}
                            <br />
                            <br />
                            <span className="cursor-pointer">Click to close</span>
                        </div>
                    </dialog>
                    <dialog
                        onClick={() => {
                            setOpen2(false);
                        }}
                        open={open2}
                        style={{padding:"10px", border:"1px solid black"}}
                    >
                        <div>
                            No trading record for this day
                            <br />
                            <br />
                            <span className="cursor-pointer">Click to close</span>
                        </div>
                    </dialog>
                    <CalendarHeatmap
                        showWeekdayLabels={true}
                        startDate={date.start}
                        weekdayLabels={["Mon", "Mon", "Wed", "Wed", "Fri", "Fri", "Sun"]}
                        endDate={date.end}
                        onClick={handleClick}
                        // horizontal={false}
                        values={info}
                    />
                </div>
                // </Grid>
            ))}
        </Carousel>
        // </Grid>
    );
}

export default TradingDays;