import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { AccContext } from "../../context/OtherContext";
// import axios from '../../axios';
import ReasonForRemoval from "../modals/ReasonForRemoval";

function ClanTable(props) {
  const { values, values4 } = React.useContext(AccContext);
  // const [globalRefresh, setGlobalRefresh] = values2
  const [memId, setMemId] = React.useState(null);
  const [memName, setMemName] = React.useState(null);
  const [openReason, setOpenReason] = React.useState(false);

  // eslint-disable-next-line no-unused-vars
  const [isAdmin] = values;
  const [countries] = values4;
  console.log(props.data);
  // var name = "Joseph Edet"
  // console.log(name.split(" "))

  // const removeMember = async (memberId, name) => {
  //   console.log(memberId)
  //   axios.put(`leaderboard/clan_members/${props.clanId}/`, { member_id: memberId, action: "remove" }).then(res => {
  //     console.log(res)
  //     props.setRefresh(!props.refresh)
  //     setGlobalRefresh(!globalRefresh)
  //     props.showRequestSuccess(`Sucessfully removed ${name}`)
  //   }).catch(err => {
  //     console.log(err)
  //     props.showRequestFailed(`Failed to remove ${name}`)
  //   })
  // }

  return (
    <div className="bg-white w-[100%]">
      <div className="w-[100%] overflow-scroll lg:max-h-[760px] max-h-[790px] no-scrollbar">
        <table className="w-[100%] border-collapse">
          <thead>
            <tr className="w-[100%]">
              {props.column.map((row) => (
                <td
                  className={`text-center py-[1rem] px-[1rem] whitespace-nowrap font-plus ${
                    row.key === "total-profit"
                      ? "text-[#00C500]"
                      : row.key === "clan"
                      ? "text-[#FFA500]"
                      : "text-[#151515]"
                  } text-[0.875rem] font-semibold`}
                  key={row.key}
                >
                  {row.label}
                </td>
              ))}
            </tr>
          </thead>
          <tbody className="w-[100%]">
            {props.data
              .sort((a, b) => {
                // Check if either item does not have a member_id
                if (
                  !a.hasOwnProperty("member_id") &&
                  !b.hasOwnProperty("member_id")
                ) {
                  return 0;
                }
                if (!a.hasOwnProperty("member_id")) {
                  return -1;
                }
                if (!b.hasOwnProperty("member_id")) {
                  return 1;
                }

                // Both items have member_id, sort by profit
                return b.profit - a.profit;
              })
              .map((item, index) => (
                <tr key={item.name} className="border-y border-gray-200">
                  <td className="text-center py-[1rem] px-[0.5rem] pl-[0.3rem] lg:pl-[0.5rem] font-plus text-[0.875rem] font-medium text-[#151515]">
                    {!item.member_id ? "Admin" : index + 1}.
                  </td>
                  <td className="whitespace-nowrap text-center py-[1rem] lg:px-[0.5rem] font-plus text-[0.875rem] font-medium text-[#151515]">
                    {item.name?.split(" ")[0]}
                  </td>
                  {/* <td className="text-center py-[1rem] lg:px-[0.5rem] font-plus text-[0.875rem] font-medium text-[#151515]">
                  {item.acc_size}
                </td>
                <td className="text-center py-[1rem] lg:px-[0.5rem] font-plus text-[0.875rem] font-medium text-[#151515]">
                  {item.typeofaccount}
                </td> */}
                  <td
                    className={`text-center py-[1rem] px-[0.5rem] font-plus text-[0.875rem] font-medium ${
                      item.profit < 0 ? "text-red-500" : "text-[#00C500]"
                    }`}
                  >
                    {item.profit || 0}%
                  </td>
                  <td className="flex justify-center py-[1rem] px-[0.5rem] font-plus text-[0.875rem] font-medium text-white">
                    {countries
                      .filter(
                        (country) =>
                          country.countryName.toLowerCase() ===
                          item.country.toLowerCase()
                      )
                      .map((count) => (
                        <img
                          key={count.countryName}
                          className="w-[1.75rem] h-[1.75rem] rounded-full"
                          src={count.flagImageUrl}
                          alt=""
                        />
                      ))}
                  </td>
                  <td className="text-center whitespace-nowrap py-[1rem] px-[0.5rem] font-plus text-[0.875rem] font-medium text-[#FFA500]">
                    {item.clan}
                  </td>
                  {props.isAdmin === true && !props.traderEmail && (
                    <td className="pr-5 lg:pr-0">
                      <Menu as="div">
                        <Menu.Button
                          className={`Tab01 relative border-none inline-block text-left`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M5 14.75C3.48 14.75 2.25 13.52 2.25 12C2.25 10.48 3.48 9.25 5 9.25C6.52 9.25 7.75 10.48 7.75 12C7.75 13.52 6.52 14.75 5 14.75ZM5 10.75C4.31 10.75 3.75 11.31 3.75 12C3.75 12.69 4.31 13.25 5 13.25C5.69 13.25 6.25 12.69 6.25 12C6.25 11.31 5.69 10.75 5 10.75Z"
                              fill="#808080"
                            />
                            <path
                              d="M19 14.75C17.48 14.75 16.25 13.52 16.25 12C16.25 10.48 17.48 9.25 19 9.25C20.52 9.25 21.75 10.48 21.75 12C21.75 13.52 20.52 14.75 19 14.75ZM19 10.75C18.31 10.75 17.75 11.31 17.75 12C17.75 12.69 18.31 13.25 19 13.25C19.69 13.25 20.25 12.69 20.25 12C20.25 11.31 19.69 10.75 19 10.75Z"
                              fill="#808080"
                            />
                            <path
                              d="M12 14.75C10.48 14.75 9.25 13.52 9.25 12C9.25 10.48 10.48 9.25 12 9.25C13.52 9.25 14.75 10.48 14.75 12C14.75 13.52 13.52 14.75 12 14.75ZM12 10.75C11.31 10.75 10.75 11.31 10.75 12C10.75 12.69 11.31 13.25 12 13.25C12.69 13.25 13.25 12.69 13.25 12C13.25 11.31 12.69 10.75 12 10.75Z"
                              fill="#808080"
                            />
                          </svg>
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute lg:right-24 right-5 border z-10 mt-0 w-[145px] origin-top-right divide-y divide-gray-300 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none flex flex-col gap-1">
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={() => {
                                    setMemId(item.member_id);
                                    setMemName(item.name);
                                    setOpenReason(true);
                                  }}
                                  className={`${
                                    active
                                      ? "bg-[#359602] text-white font-poppins font-medium"
                                      : "text-gray-900 bg-white"
                                  } group flex w-full items-center  font-poppins outline-none border-none font-medium px-2 py-3 text-base`}
                                >
                                  Request Removal
                                </button>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <ReasonForRemoval
        memberName={memName}
        memberId={memId}
        clanId={props?.clanId}
        refresh={props.refresh}
        setRefresh={props.setRefresh}
        isOpen={openReason}
        setIsOpen={setOpenReason}
        showRequestSuccess={props.showRequestSuccess}
        showRequestFailed={props.showRequestFailed}
      />
    </div>
  );
}

export default ClanTable;
